import React from 'react'
import { SectionWrapper, TableWrapper, Title, Wrapper } from './Table.styled'
import { Container, Col, Row } from 'react-grid-system';

type Props = {
  title?: string;
  tableData: string[][]
}

const data = [
  ['Features', 'Basicmanagement', 'Advanced managment level 1', 'Advanced managment level 2', 'AdvancedEnterprise-1', 'AdvancedEnterprise-2', 'AdvancedEnterprise-3'],
  ['Number of accounts on the server', '-', '1 - 30', '31 - 80', '81 - 130', '131 - 180', 'Unlimited'],
  ['Test the components of hardware', '✔', '✔', '✔', '✔', '✔', '✔'],
  ['The solution of the problems of hardware', '✔', '✔', '✔', '✔', '✔', '✔'],
  ['Solving network problems', '✔', '✔', '✔', '✔', '✔', '✔'],
  ['Retrieve Login Data', '✔', '✔', '✔', '✔', '✔', '✔'],
  ['Re-installation of the operating system', '✔', '✔', '✔', '✔', '✔', '✔'],
  ['The Periodic System Updates', '✘', '✔', '✔', '✔', '✔', '✔'],
  ['Firewall layer', '✘', '✔', '✔', '✔', '✔', '✔'],
  ['Handle unexpected issues and gaps', '✘', '✔', '✔', '✔', '✔', '✔'],
  ['Secure Operating System', '✘', '✔', '✔', '✔', '✔', '✔'],
];
function Table({tableData, title}: Props) {
  return (
    <SectionWrapper>
      <Container fluid>
        <Row justify='center' >
          <Col xs={12} >
            <Title>{title}</Title>
          </Col>
          <Col xs={12} >
          <Wrapper>
            <TableWrapper>
              <thead>
                <tr>
                  {tableData[0].map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.slice(1).map((row, index) => (
                  <tr key={index}>
                    {row.map((cell, index)   => (
                      <td key={index}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </TableWrapper>
          </Wrapper>
          </Col>
        </Row>
      </Container>
    </SectionWrapper>
  )
}

export default Table