import {memo, useState} from 'react'
import { NavWrapper, Logo, Ul, Li, NavLink, OverLay, HeaderActionsWrapper, SecoundUl } from './Navbarr.styled'
import { Col, Container, Row } from 'react-grid-system';
import LogoImg from '../../../images/Hosting.svg'
import MemoLang from '../Language';
import MemoCommonButton from '../CommonButton/CommonButton';
import BurgerButton from './BurgerButton';
import MemoDrawer from './Drawer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {}

function Navbarr({}: Props) {  
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.maxHeight = '100vh'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Enable scrolling
    }
  };
  return (
    <NavWrapper>
      <Container fluid>
        <Row>
          <Col xs={2} >
          <Link to="/">
            <Logo src={LogoImg} alt='dimofinf Logo' width={148} height={43}/>
          </Link>
          </Col>
          <Col xs={6} >
            <Ul>
              <Li>
                <NavLink to="/saudi-hosting">
                  {t('header.saudiHosting')}
                </NavLink>
              </Li>
              <Li>
                <NavLink to="/dedicated-hosting">
                  {t('header.europeHosting')} 
                </NavLink>
              </Li>
              <Li>
                <NavLink to="/cloud-hosting">
                  {t('header.cloudServers')} 
                </NavLink>
              </Li>
              <Li>
                <NavLink to="/">
                  {t('header.addOn')} 
                </NavLink>
              </Li>
              <Li>
                <NavLink to="/">
                  {t('header.emailExchange')}
                </NavLink>
              </Li>
            </Ul>
          </Col>
          <Col xs={4} >
            <HeaderActionsWrapper>
              <MemoCommonButton name={t('header.becomeReseller')} />
              <MemoCommonButton name={t('header.openTicket')} variant='filled' />
              <MemoLang/> 
            </HeaderActionsWrapper>
            <BurgerButton  isOpen={isOpen} setIsOpen={handleMenuToggle} />
          </Col>
          <Col xs={12} >
            <SecoundUl>
              <Li>
                <NavLink to="/saudi-hosting">
                  Saudi Hosting 
                </NavLink>
              </Li>
              <Li>
                <NavLink to="/">
                  Europe Hosting 
                </NavLink>
              </Li>
              <Li>
                <NavLink to="/">
                  Cloud Servers 
                </NavLink>
              </Li>
              <Li>
                <NavLink to="/">
                  Add on 
                </NavLink>
              </Li>
              <Li>
                <NavLink to="/">
                  Email Exchange
                </NavLink>
              </Li>
            </SecoundUl>
          </Col>
        </Row>
      </Container>
      <OverLay isOpen={isOpen} onClick={handleMenuToggle} />
      <MemoDrawer isOpen={isOpen} setIsOpen={handleMenuToggle} />
    </NavWrapper>
  )
}

const Navbar = memo(Navbarr)

export default Navbar