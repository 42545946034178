import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MemoMainHostingSection from '../../components/MainHostingSection/MainHostingSection';
import Bg from "../../images/bg.webp";
import MemoNestedTabs from '../../components/NestedTabs/NestedTabs';
import MemoFeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import MemoSpecialSection from '../../components/HomePage/SpecialSection/SpecialSection';
import MemoClientOpinionSection from '../../components/HomePage/ClientOpinionSection/ClientOpinionSection';
import MemoCollapseSection from '../../components/CollapseSection/CollapseSection';

type Props = {}

function DedicatedHosting({}: Props) {
  const { t } = useTranslation();

  const mainSectionData = [
    {
      title: "Launch your site with a Saudi identity with",
      subTitle: "saudi Servers",
      description: "Establish your site now with Saudi Shared Hosting with the first host in the Arab world .",
      url: "/"
    },
    {
      title: "Launch your site with a Saudi identity with",
      subTitle: "saudi Servers",
      description: "Establish your site now with Saudi Shared Hosting with the first host in the Arab world .",
      url: "/"
    }
  ]

  const featuresData = {
    title: 'View Our',
    subTitle: 'Dedicated Hosting Features',
    description: 'We always aim at providing the best web hosting at the least prices as Dimofinf has its own servers which .',
    action:'',
    cards: [
      {
        id: 1,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 2,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 3,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 5,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 6,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 7,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 8,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
      {
        id: 9,
        title: 'Failover',
        description: 'Cloud servers provide failover which means that if the host container down the server will immediately run on another container within minutes to avoid your site to be stopped.',
        star: true,
        img: false,
      },
    ]
  }
  const questions = [
    t('common.linux'),
    t('common.windows')
  ]
  return (
    <>
      <MemoMainHostingSection 
        image={Bg}
        data={mainSectionData}
      />
      <MemoNestedTabs 
        title='Our Pricing Plan' 
        subTitle='Saudi Shared Hosting Plans' 
        mainTabs={['Single-CPU2', 'DUAL-CPU2']}
        nestedTabs={['Free Management', 'Advanced Management']}
      /> 
      <MemoFeaturesSection 
        data={featuresData}
      /> 
      <MemoCollapseSection 
        title={t('saudi-hosting.questions.subTitle')}
        subTitle={t('saudi-hosting.questions.title')}
        tabsList={questions}
        path='saudi'
      />
      <MemoSpecialSection white/>
      <MemoClientOpinionSection/>
    </>
  )
}

const MemoDedicatedHosting = React.memo(DedicatedHosting)

export default MemoDedicatedHosting;