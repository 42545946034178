import styled from "styled-components";
import { DotsWrapper } from "../Common/AnimatedDots.tsx/AnimatedDots.styled";
import { HeaderDescription } from "../HomePage/SpecialSection/SpecialSection.styled";

export const SectionWrapper = styled.section`
  background-color: #fff;
  ${DotsWrapper} {
    z-index: 0;
  }
  ${HeaderDescription}{
    margin-bottom: 30px;
  }
`;

export const SectionContent = styled.div`
  max-width: 94vw;
  margin: auto;
`;

export const SectionData = styled.div`
  padding: 90px 0;
`;