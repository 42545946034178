import React, { memo } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import MemoMainHostingSection from '../../components/MainHostingSection/MainHostingSection';
import Bg from "../../images/bg.webp";
import MemoNestedTabs from '../../components/NestedTabs/NestedTabs';
import FeaturesTabs from '../../components/FeaturesTabs/FeaturesTabs';
import MemoCollapseSection from '../../components/CollapseSection/CollapseSection';

type Props = {}

function SharedHosting({}: Props) {
  const { t } = useTranslation();
  const mainSectionData = [
    {
      title: t('shared-hosting.hero.linux.title'),
      subTitle: t('shared-hosting.hero.linux.subTitle'),
      description: t('shared-hosting.hero.linux.description'),
      url: "/"
    },
    {
      title: t('shared-hosting.hero.windows.title'),
      subTitle: t('shared-hosting.hero.windows.subTitle'),
      description: t('shared-hosting.hero.windows.description'),
      url: "/"
    }
  ]
  const plans = {
    linux: {
      months: [
        {
          title: t('shared-hosting.plans.linux.content1_1.plan1.title'),
          price: t('shared-hosting.plans.linux.content1_1.plan1.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_1.plan1.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan1.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan1.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan1.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan1.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan1.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan1.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan1.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan1.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan1.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan1.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan1.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan1.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan1.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan1.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan1.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.linux.content1_1.plan2.title'),
          price: t('shared-hosting.plans.linux.content1_1.plan2.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_1.plan2.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan2.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan2.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan2.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan2.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan2.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan2.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan2.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan2.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan2.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan2.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan2.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan2.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan2.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan2.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan2.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.linux.content1_1.plan3.title'),
          price: t('shared-hosting.plans.linux.content1_1.plan3.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_1.plan3.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan3.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan3.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan3.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan3.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan3.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan3.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan3.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan3.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan3.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan3.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan3.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan3.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan3.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan3.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan3.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.linux.content1_1.plan4.title'),
          price: t('shared-hosting.plans.linux.content1_1.plan4.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_1.plan4.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan4.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan4.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan4.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan4.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan4.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan4.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan4.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan4.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan4.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan4.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan4.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan4.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan4.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_1.plan4.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_1.plan4.features.item8.content'),
            },
          ],
          url: '/'
        },
      ],
      year: [
        {
          title: t('shared-hosting.plans.linux.content1_2.plan1.title'),
          price: t('shared-hosting.plans.linux.content1_2.plan1.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_2.plan1.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan1.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan1.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan1.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan1.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan1.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan1.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan1.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan1.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan1.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan1.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan1.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan1.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan1.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan1.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan1.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.linux.content1_2.plan2.title'),
          price: t('shared-hosting.plans.linux.content1_2.plan2.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_2.plan2.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan2.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan2.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan2.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan2.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan2.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan2.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan2.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan2.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan2.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan2.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan2.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan2.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan2.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan2.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan2.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.linux.content1_2.plan3.title'),
          price: t('shared-hosting.plans.linux.content1_2.plan3.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_2.plan3.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan3.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan3.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan3.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan3.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan3.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan3.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan3.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan3.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan3.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan3.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan3.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan3.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan3.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan3.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan3.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.linux.content1_2.plan4.title'),
          price: t('shared-hosting.plans.linux.content1_2.plan4.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_2.plan4.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan4.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan4.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan4.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan4.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan4.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan4.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan4.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan4.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan4.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan4.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan4.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan4.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan4.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_2.plan4.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_2.plan4.features.item8.content'),
            },
          ],
          url: '/'
        },
      ],
      twoYears: [
        {
          title: t('shared-hosting.plans.linux.content1_3.plan1.title'),
          price: t('shared-hosting.plans.linux.content1_3.plan1.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_3.plan1.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan1.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan1.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan1.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan1.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan1.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan1.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan1.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan1.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan1.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan1.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan1.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan1.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan1.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan1.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan1.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.linux.content1_3.plan2.title'),
          price: t('shared-hosting.plans.linux.content1_3.plan2.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_3.plan2.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan2.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan2.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan2.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan2.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan2.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan2.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan2.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan2.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan2.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan2.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan2.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan2.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan2.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan2.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan2.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.linux.content1_3.plan3.title'),
          price: t('shared-hosting.plans.linux.content1_3.plan3.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_3.plan3.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan3.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan3.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan3.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan3.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan3.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan3.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan3.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan3.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan3.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan3.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan3.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan3.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan3.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan3.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan3.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.linux.content1_3.plan4.title'),
          price: t('shared-hosting.plans.linux.content1_3.plan4.price'),
          data: [
            {
              title: t('shared-hosting.plans.linux.content1_3.plan4.features.item1.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan4.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan4.features.item2.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan4.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan4.features.item3.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan4.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan4.features.item4.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan4.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan4.features.item5.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan4.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan4.features.item6.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan4.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan4.features.item7.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan4.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.linux.content1_3.plan4.features.item8.title'),
              content: t('shared-hosting.plans.linux.content1_3.plan4.features.item8.content'),
            },
          ],
          url: '/'
        },
      ]
    },
    windows: {
      months: [
        {
          title: t('shared-hosting.plans.windows.content1_1.plan1.title'),
          price: t('shared-hosting.plans.windows.content1_1.plan1.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_1.plan1.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan1.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan1.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan1.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan1.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan1.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan1.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan1.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan1.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan1.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan1.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan1.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan1.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan1.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan1.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan1.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.windows.content1_1.plan2.title'),
          price: t('shared-hosting.plans.windows.content1_1.plan2.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_1.plan2.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan2.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan2.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan2.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan2.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan2.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan2.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan2.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan2.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan2.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan2.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan2.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan2.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan2.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan2.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan2.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.windows.content1_1.plan3.title'),
          price: t('shared-hosting.plans.windows.content1_1.plan3.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_1.plan3.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan3.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan3.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan3.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan3.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan3.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan3.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan3.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan3.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan3.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan3.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan3.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan3.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan3.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan3.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan3.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.windows.content1_1.plan4.title'),
          price: t('shared-hosting.plans.windows.content1_1.plan4.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_1.plan4.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan4.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan4.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan4.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan4.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan4.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan4.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan4.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan4.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan4.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan4.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan4.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan4.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan4.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_1.plan4.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_1.plan4.features.item8.content'),
            },
          ],
          url: '/'
        },
      ],
      year: [
        {
          title: t('shared-hosting.plans.windows.content1_2.plan1.title'),
          price: t('shared-hosting.plans.windows.content1_2.plan1.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_2.plan1.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan1.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan1.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan1.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan1.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan1.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan1.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan1.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan1.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan1.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan1.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan1.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan1.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan1.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan1.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan1.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.windows.content1_2.plan2.title'),
          price: t('shared-hosting.plans.windows.content1_2.plan2.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_2.plan2.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan2.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan2.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan2.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan2.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan2.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan2.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan2.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan2.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan2.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan2.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan2.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan2.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan2.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan2.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan2.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.windows.content1_2.plan3.title'),
          price: t('shared-hosting.plans.windows.content1_2.plan3.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_2.plan3.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan3.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan3.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan3.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan3.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan3.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan3.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan3.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan3.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan3.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan3.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan3.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan3.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan3.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan3.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan3.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.windows.content1_2.plan4.title'),
          price: t('shared-hosting.plans.windows.content1_2.plan4.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_2.plan4.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan4.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan4.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan4.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan4.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan4.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan4.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan4.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan4.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan4.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan4.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan4.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan4.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan4.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_2.plan4.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_2.plan4.features.item8.content'),
            },
          ],
          url: '/'
        },
      ],
      twoYears: [
        {
          title: t('shared-hosting.plans.windows.content1_3.plan1.title'),
          price: t('shared-hosting.plans.windows.content1_3.plan1.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_3.plan1.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan1.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan1.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan1.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan1.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan1.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan1.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan1.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan1.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan1.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan1.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan1.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan1.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan1.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan1.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan1.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.windows.content1_3.plan2.title'),
          price: t('shared-hosting.plans.windows.content1_3.plan2.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_3.plan2.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan2.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan2.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan2.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan2.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan2.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan2.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan2.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan2.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan2.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan2.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan2.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan2.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan2.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan2.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan2.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.windows.content1_3.plan3.title'),
          price: t('shared-hosting.plans.windows.content1_3.plan3.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_3.plan3.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan3.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan3.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan3.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan3.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan3.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan3.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan3.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan3.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan3.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan3.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan3.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan3.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan3.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan3.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan3.features.item8.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('shared-hosting.plans.windows.content1_3.plan4.title'),
          price: t('shared-hosting.plans.windows.content1_3.plan4.price'),
          data: [
            {
              title: t('shared-hosting.plans.windows.content1_3.plan4.features.item1.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan4.features.item1.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan4.features.item2.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan4.features.item2.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan4.features.item3.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan4.features.item3.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan4.features.item4.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan4.features.item4.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan4.features.item5.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan4.features.item5.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan4.features.item6.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan4.features.item6.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan4.features.item7.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan4.features.item7.content'),
            },
            {
              title: t('shared-hosting.plans.windows.content1_3.plan4.features.item8.title'),
              content: t('shared-hosting.plans.windows.content1_3.plan4.features.item8.content'),
            },
          ],
          url: '/'
        },
      ]
    }
  }
  const featuresMainTabs = [t('common.linux'), t('common.windows')];

  const featuresLinuxTabs = [
    t('shared-hosting.features.linux.tab1.title'),
    t('shared-hosting.features.linux.tab2.title'),
    t('shared-hosting.features.linux.tab3.title'),
    t('shared-hosting.features.linux.tab4.title'),
    t('shared-hosting.features.linux.tab5.title'),
  ]
  const featuresWindowsTabs = [
    t('shared-hosting.features.windows.tab1.title'),
    t('shared-hosting.features.windows.tab2.title'),
    t('shared-hosting.features.windows.tab3.title'),
    t('shared-hosting.features.windows.tab4.title'),
  ]

  const featureList = [
    [
      {
        panel: {
          features: [
            {
              title: t('shared-hosting.features.linux.tab1.feature1.title'),
              description: t('shared-hosting.features.linux.tab1.feature1.description')
            },
            {
              title: t('shared-hosting.features.linux.tab1.feature2.title'),
              description: t('shared-hosting.features.linux.tab1.feature2.description')
            },
            {
              title: t('shared-hosting.features.linux.tab1.feature3.title'),
              description: t('shared-hosting.features.linux.tab1.feature3.description')
            },
            {
              title: t('shared-hosting.features.linux.tab1.feature4.title'),
              description: t('shared-hosting.features.linux.tab1.feature4.description')
            },
            {
              title: t('shared-hosting.features.linux.tab1.feature5.title'),
              description: t('shared-hosting.features.linux.tab1.feature5.description')
            },
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('shared-hosting.features.linux.tab2.feature1.title'),
              description: t('shared-hosting.features.linux.tab2.feature1.description')
            },
            {
              title: t('shared-hosting.features.linux.tab2.feature2.title'),
              description: t('shared-hosting.features.linux.tab2.feature2.description')
            },
            {
              title: t('shared-hosting.features.linux.tab2.feature3.title'),
              description: t('shared-hosting.features.linux.tab2.feature3.description')
            },
            {
              title: t('shared-hosting.features.linux.tab2.feature4.title'),
              description: t('shared-hosting.features.linux.tab2.feature4.description')
            },
            {
              title: t('shared-hosting.features.linux.tab2.feature5.title'),
              description: t('shared-hosting.features.linux.tab2.feature5.description')
            },
            {
              title: t('shared-hosting.features.linux.tab2.feature6.title'),
              description: t('shared-hosting.features.linux.tab2.feature6.description')
            },
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('shared-hosting.features.linux.tab3.feature1.title'),
              description: t('shared-hosting.features.linux.tab3.feature1.description')
            },
            {
              title: t('shared-hosting.features.linux.tab3.feature2.title'),
              description: t('shared-hosting.features.linux.tab3.feature2.description')
            },
            {
              title: t('shared-hosting.features.linux.tab3.feature3.title'),
              description: t('shared-hosting.features.linux.tab3.feature3.description')
            },
            {
              title: t('shared-hosting.features.linux.tab3.feature4.title'),
              description: t('shared-hosting.features.linux.tab3.feature4.description')
            },
            {
              title: t('shared-hosting.features.linux.tab3.feature5.title'),
              description: t('shared-hosting.features.linux.tab3.feature5.description')
            },
            
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('shared-hosting.features.linux.tab4.feature1.title'),
              description: t('shared-hosting.features.linux.tab4.feature1.description')
            },
            {
              title: t('shared-hosting.features.linux.tab4.feature2.title'),
              description: t('shared-hosting.features.linux.tab4.feature2.description')
            },
            {
              title: t('shared-hosting.features.linux.tab4.feature3.title'),
              description: t('shared-hosting.features.linux.tab4.feature3.description')
            },
            {
              title: t('shared-hosting.features.linux.tab4.feature4.title'),
              description: t('shared-hosting.features.linux.tab4.feature4.description')
            },
            
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('shared-hosting.features.linux.tab5.feature1.title'),
              description: t('shared-hosting.features.linux.tab5.feature1.description')
            },
            {
              title: t('shared-hosting.features.linux.tab5.feature2.title'),
              description: t('shared-hosting.features.linux.tab5.feature2.description')
            },
            {
              title: t('shared-hosting.features.linux.tab5.feature3.title'),
              description: t('shared-hosting.features.linux.tab5.feature3.description')
            },
            {
              title: t('shared-hosting.features.linux.tab5.feature4.title'),
              description: t('shared-hosting.features.linux.tab5.feature4.description')
            },
            {
              title: t('shared-hosting.features.linux.tab5.feature5.title'),
              description: t('shared-hosting.features.linux.tab5.feature5.description')
            },
            {
              title: t('shared-hosting.features.linux.tab5.feature6.title'),
              description: t('shared-hosting.features.linux.tab5.feature6.description')
            },
            
          ]
        }
      },
    ],
    
  ]

  const windowsFeaturesList = [
    [
      {
        panel: {
          features: [
            {
              title: t('shared-hosting.features.windows.tab1.feature1.title'),
              description: t('shared-hosting.features.windows.tab1.feature1.description')
            },
            {
              title: t('shared-hosting.features.windows.tab1.feature2.title'),
              description: t('shared-hosting.features.windows.tab1.feature2.description')
            },
            {
              title: t('shared-hosting.features.windows.tab1.feature3.title'),
              description: t('shared-hosting.features.windows.tab1.feature3.description')
            },
            {
              title: t('shared-hosting.features.windows.tab1.feature5.title'),
              description: t('shared-hosting.features.windows.tab1.feature5.description')
            },
            {
              title: t('shared-hosting.features.windows.tab1.feature6.title'),
              description: t('shared-hosting.features.windows.tab1.feature6.description')
            },
            {
              title: t('shared-hosting.features.windows.tab1.feature4.title'),
              description: t('shared-hosting.features.windows.tab1.feature4.description')
            },
            
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('shared-hosting.features.windows.tab2.feature1.title'),
              description: t('shared-hosting.features.windows.tab2.feature1.description')
            },
            {
              title: t('shared-hosting.features.windows.tab2.feature2.title'),
              description: t('shared-hosting.features.windows.tab2.feature2.description')
            },
            {
              title: t('shared-hosting.features.windows.tab2.feature3.title'),
              description: t('shared-hosting.features.windows.tab2.feature3.description')
            },
            {
              title: t('shared-hosting.features.windows.tab2.feature4.title'),
              description: t('shared-hosting.features.windows.tab2.feature4.description')
            },
            {
              title: t('shared-hosting.features.windows.tab2.feature5.title'),
              description: t('shared-hosting.features.windows.tab2.feature5.description')
            },
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('shared-hosting.features.windows.tab3.feature1.title'),
              description: t('shared-hosting.features.windows.tab3.feature1.description')
            },
            {
              title: t('shared-hosting.features.windows.tab3.feature2.title'),
              description: t('shared-hosting.features.windows.tab3.feature2.description')
            },
            {
              title: t('shared-hosting.features.windows.tab3.feature3.title'),
              description: t('shared-hosting.features.windows.tab3.feature3.description')
            },
            {
              title: t('shared-hosting.features.windows.tab3.feature4.title'),
              description: t('shared-hosting.features.windows.tab3.feature4.description')
            },
            
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('shared-hosting.features.windows.tab4.feature1.title'),
              description: t('shared-hosting.features.windows.tab4.feature1.description')
            },
          ]
        }
      },
    ],
  ]

  const questions = [
    t('common.linux'),
    t('common.windows')
  ]
  return (
    <>
      <Helmet>
        <title>{t("shared-hosting.meta.title")}</title>
      </Helmet>
      <MemoMainHostingSection 
        image={Bg}
        data={mainSectionData}
      />
      <MemoNestedTabs 
        title={t('shared-hosting.plans.title')}
        subTitle={t('shared-hosting.plans.subTitle')}
        golden
        mainTabs={[t('common.linux'), t('common.windows')]}
        nestedTabs={[t('shared-hosting.plans.nestedTabs.tab1'), t('shared-hosting.plans.nestedTabs.tab2'), t('shared-hosting.plans.nestedTabs.tab3')]}
        plans={plans}
      />
      <FeaturesTabs
        title= {t('shared-hosting.features.title')}
        subTitle= {t('shared-hosting.features.subTitle')}
        description= {t('shared-hosting.features.description')}
        mainTabList={featuresMainTabs}
        windowsTabList={featuresWindowsTabs}
        linuxTabList={featuresLinuxTabs}
        linuxData={featureList}
        windowsData={windowsFeaturesList}
        // tabsList={}
        data={featureList}
      />
      <MemoCollapseSection 
        title={t('shared-hosting.questions.title')}
        subTitle={t('shared-hosting.questions.subTitle')}
        tabsList={questions}
        path='shared'
      />
    </>
  )
}
const MemoSharedHosting = memo(SharedHosting);

export default MemoSharedHosting;