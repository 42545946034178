import styled from "styled-components";

export const SectionWrapper = styled.section`
  padding: 60px 0;
`;

export const Title = styled.h4`
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
  color: #000;
  text-align: center;
`;
export const Wrapper = styled.div`
  overflow-x: auto;
  `;
export const TableWrapper = styled.table`
  margin: 0 auto;
  color: #fff;
  padding: 0;
  thead > tr > th{
    background-color: #48988D;
    color: #fff;
    white-space: nowrap;
  }
  tbody > tr > td {
    &:first-of-type {
      background-color: #48988D;
      color: #fff;
    }
  }
  th, td {
    padding: 12px;
    color: #000;
    text-align: center;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    &:last-of-type {
      /* border-right: 0 */
    }
  }

`;

// export const 