import styled from "styled-components";
import { DotsWrapper } from "../Common/AnimatedDots.tsx/AnimatedDots.styled";
import { HeaderDescription } from "../HomePage/PlansSection/PlansSection.styled";

export const SectionWrapper = styled.section`
  /* background-color: #fff; */
  ${DotsWrapper} {
    z-index: 0;
  }
  ${HeaderDescription}{
    margin-bottom: 16px;
  }
`;

export const SectionContent = styled.div`
  max-width: 94vw;
  margin: auto;
`;

export const SectionData = styled.div`
  padding: 52px 0 0 0;
`;

export const GrrenTitle = styled.h5`
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  color: #48988D;
`;

export const HeaderTitle = styled.h4`
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #000;
`;

// export const HeaderDescription = styled.p`
//   font-size: 18px;
//   font-weight: 300;
//   line-height: 30px;
//   color: #39414C;
//   max-width: 475px;
// `;