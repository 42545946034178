// ts-nocheck
import { memo } from 'react'
import CloudIcon from "../../images/icons/cloud.svg"
import { Card, Badge, CardHeader, Icon, Title, Description, CardFooter, Price, OrderBtn, FeatureList, FeatureItem } from './MiniPlanCard.styled';
import Link from 'react-router-dom';

type Feature = {
  item: string,
  content: string
}

type Props = {
  padge?: string;
  title?: string;
  features?: Feature[];
  description?: string;
  price?: string;
  actionLink?: string;
  fav?: boolean;
  icon: string;
}

const MiniPlanCard = ({padge, title, features, description, price, actionLink, fav, icon}: Props) => {
  return (
    <Card fav={fav}>
      <Badge>{padge}</Badge>
      <CardHeader>
        <Icon src={icon} alt='Cloud Icon' />
        <Title>{title}</Title>
      </CardHeader>
      <FeatureList>
        {features?.map((feature, i) => (
          <FeatureItem key={i} >
            <p>{feature.item}</p>
            <span>{feature.content}</span>
          </FeatureItem>
        ))}
      </FeatureList>
      {/* <Description>{description}</Description> */}
      <CardFooter>
        <Price>{price}</Price>
        <OrderBtn to={`/${actionLink}`} >Order Now</OrderBtn>
      </CardFooter>
    </Card>
  )
}

const MemoMiniPlanCard = memo(MiniPlanCard)

export default MemoMiniPlanCard;