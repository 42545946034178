import { memo } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';
import MemoMainHostingSection from '../../components/MainHostingSection/MainHostingSection';
import Bg from "../../images/bg.webp";
import MemoNestedTabs from '../../components/NestedTabs/NestedTabs';
// import MemoFeaturesSection from '../../components/FeaturesSection/FeaturesSection';
// import MemoSpecialSection from '../../components/HomePage/SpecialSection/SpecialSection';
// import MemoClientOpinionSection from '../../components/HomePage/ClientOpinionSection/ClientOpinionSection';
import MemoCollapseSection from '../../components/CollapseSection/CollapseSection';
import FeaturesTabs from '../../components/FeaturesTabs/FeaturesTabs';

type Props = {}

function SaudiHosting({}: Props) {
  const { t } = useTranslation();

  const mainSectionData = [
    {
      title: t('saudi-hosting.hero.linux.title'),
      subTitle: t('saudi-hosting.hero.linux.subTitle'),
      description: t('saudi-hosting.hero.linux.description'),
      url: "/"
    },
    {
      title: t('saudi-hosting.hero.windows.title'),
      subTitle: t('saudi-hosting.hero.windows.subTitle'),
      description: t('saudi-hosting.hero.windows.description'),
      url: "/"
    }
  ]

  const plans = {
    linux: {
      months: [
        {
          title: t('saudi-hosting.plans.linux.content1_1.plan1.title'),
          price: t('saudi-hosting.plans.linux.content1_1.plan1.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan1.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan1.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan1.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan1.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan1.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan1.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan1.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan1.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan1.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan1.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan1.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan1.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.linux.content1_1.plan2.title'),
          price: t('saudi-hosting.plans.linux.content1_1.plan2.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan2.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan2.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan2.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan2.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan2.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan2.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan2.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan2.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan2.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan2.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan2.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan2.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.linux.content1_1.plan3.title'),
          price: t('saudi-hosting.plans.linux.content1_1.plan3.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan3.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan3.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan3.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan3.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan3.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan3.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan3.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan3.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan3.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan3.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan3.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan3.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.linux.content1_1.plan4.title'),
          price: t('saudi-hosting.plans.linux.content1_1.plan4.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan4.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan4.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan4.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan4.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan4.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan4.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan4.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan4.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan4.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan4.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_1.plan4.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_1.plan4.features.item6.content'),
            },
          ],
          url: '/'
        },
      ],
      year: [
        {
          title: t('saudi-hosting.plans.linux.content1_2.plan1.title'),
          price: t('saudi-hosting.plans.linux.content1_2.plan1.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan1.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan1.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan1.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan1.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan1.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan1.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan1.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan1.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan1.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan1.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan1.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan1.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.linux.content1_2.plan2.title'),
          price: t('saudi-hosting.plans.linux.content1_2.plan2.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan2.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan2.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan2.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan2.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan2.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan2.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan2.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan2.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan2.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan2.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan2.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan2.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.linux.content1_2.plan3.title'),
          price: t('saudi-hosting.plans.linux.content1_2.plan3.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan3.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan3.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan3.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan3.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan3.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan3.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan3.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan3.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan3.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan3.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan3.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan3.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.linux.content1_2.plan4.title'),
          price: t('saudi-hosting.plans.linux.content1_2.plan4.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan4.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan4.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan4.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan4.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan4.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan4.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan4.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan4.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan4.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan4.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_2.plan4.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_2.plan4.features.item6.content'),
            },
          ],
          url: '/'
        },
      ],
      twoYears: [
        {
          title: t('saudi-hosting.plans.linux.content1_3.plan1.title'),
          price: t('saudi-hosting.plans.linux.content1_3.plan1.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan1.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan1.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan1.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan1.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan1.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan1.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan1.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan1.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan1.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan1.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan1.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan1.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.linux.content1_3.plan2.title'),
          price: t('saudi-hosting.plans.linux.content1_3.plan2.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan2.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan2.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan2.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan2.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan2.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan2.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan2.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan2.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan2.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan2.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan2.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan2.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.linux.content1_3.plan3.title'),
          price: t('saudi-hosting.plans.linux.content1_3.plan3.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan3.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan3.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan3.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan3.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan3.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan3.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan3.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan3.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan3.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan3.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan3.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan3.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.linux.content1_3.plan4.title'),
          price: t('saudi-hosting.plans.linux.content1_3.plan4.price'),
          data: [
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan4.features.item1.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan4.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan4.features.item2.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan4.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan4.features.item3.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan4.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan4.features.item4.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan4.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan4.features.item5.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan4.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.linux.content1_3.plan4.features.item6.title'),
              content: t('saudi-hosting.plans.linux.content1_3.plan4.features.item6.content'),
            },
          ],
          url: '/'
        },
      ]
    },
    windows: {
      months: [
        {
          title: t('saudi-hosting.plans.windows.content2_1.plan1.title'),
          price: t('saudi-hosting.plans.windows.content2_1.plan1.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan1.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan1.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan1.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan1.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan1.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan1.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan1.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan1.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan1.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan1.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan1.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan1.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.windows.content2_1.plan2.title'),
          price: t('saudi-hosting.plans.windows.content2_1.plan2.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan2.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan2.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan2.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan2.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan2.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan2.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan2.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan2.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan2.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan2.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan2.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan2.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.windows.content2_1.plan3.title'),
          price: t('saudi-hosting.plans.windows.content2_1.plan3.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan3.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan3.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan3.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan3.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan3.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan3.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan3.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan3.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan3.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan3.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan3.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan3.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.windows.content2_1.plan4.title'),
          price: t('saudi-hosting.plans.windows.content2_1.plan4.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan4.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan4.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan4.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan4.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan4.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan4.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan4.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan4.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan4.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan4.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_1.plan4.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_1.plan4.features.item6.content'),
            },
          ],
          url: '/'
        },
      ],
      year: [
        {
          title: t('saudi-hosting.plans.windows.content2_2.plan1.title'),
          price: t('saudi-hosting.plans.windows.content2_2.plan1.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan1.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan1.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan1.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan1.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan1.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan1.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan1.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan1.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan1.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan1.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan1.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan1.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.windows.content2_2.plan2.title'),
          price: t('saudi-hosting.plans.windows.content2_2.plan2.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan2.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan2.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan2.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan2.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan2.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan2.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan2.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan2.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan2.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan2.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan2.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan2.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.windows.content2_2.plan3.title'),
          price: t('saudi-hosting.plans.windows.content2_2.plan3.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan3.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan3.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan3.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan3.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan3.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan3.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan3.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan3.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan3.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan3.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan3.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan3.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.windows.content2_2.plan4.title'),
          price: t('saudi-hosting.plans.windows.content2_2.plan4.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan4.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan4.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan4.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan4.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan4.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan4.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan4.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan4.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan4.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan4.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_2.plan4.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_2.plan4.features.item6.content'),
            },
          ],
          url: '/'
        },
      ],
      twoYears: [
        {
          title: t('saudi-hosting.plans.windows.content2_3.plan1.title'),
          price: t('saudi-hosting.plans.windows.content2_3.plan1.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan1.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan1.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan1.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan1.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan1.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan1.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan1.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan1.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan1.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan1.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan1.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan1.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.windows.content2_3.plan2.title'),
          price: t('saudi-hosting.plans.windows.content2_3.plan2.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan2.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan2.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan2.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan2.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan2.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan2.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan2.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan2.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan2.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan2.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan2.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan2.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.windows.content2_3.plan3.title'),
          price: t('saudi-hosting.plans.windows.content2_3.plan3.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan3.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan3.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan3.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan3.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan3.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan3.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan3.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan3.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan3.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan3.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan3.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan3.features.item6.content'),
            },
          ],
          url: '/'
        },
        {
          title: t('saudi-hosting.plans.windows.content2_3.plan4.title'),
          price: t('saudi-hosting.plans.windows.content2_3.plan4.price'),
          data: [
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan4.features.item1.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan4.features.item1.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan4.features.item2.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan4.features.item2.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan4.features.item3.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan4.features.item3.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan4.features.item4.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan4.features.item4.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan4.features.item5.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan4.features.item5.content'),
            },
            {
              title: t('saudi-hosting.plans.windows.content2_3.plan4.features.item6.title'),
              content: t('saudi-hosting.plans.windows.content2_3.plan4.features.item6.content'),
            },
          ],
          url: '/'
        },
      ]
    }
  }

  const featuresMainTabs = [t('common.linux'), t('common.windows')];

  const featuresLinuxTabs = [
    t('saudi-hosting.features.linux.tab1.title'),
    t('saudi-hosting.features.linux.tab2.title'),
    t('saudi-hosting.features.linux.tab3.title'),
    t('saudi-hosting.features.linux.tab4.title'),
  ]
  const featuresWindowsTabs = [
    t('saudi-hosting.features.windows.tab1.title'),
    t('saudi-hosting.features.windows.tab2.title'),
    t('saudi-hosting.features.windows.tab3.title'),
    // t('saudi-hosting.features.windows.tab4.title'),
  ]

  const featureList = [
    [
      {
        panel: {
          features: [
            {
              title: t('saudi-hosting.features.linux.tab1.feature1.title'),
              description: t('saudi-hosting.features.linux.tab1.feature1.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab1.feature2.title'),
              description: t('saudi-hosting.features.linux.tab1.feature2.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab1.feature3.title'),
              description: t('saudi-hosting.features.linux.tab1.feature3.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab1.feature4.title'),
              description: t('saudi-hosting.features.linux.tab1.feature4.description')
            },
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('saudi-hosting.features.linux.tab2.feature1.title'),
              description: t('saudi-hosting.features.linux.tab2.feature1.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab2.feature2.title'),
              description: t('saudi-hosting.features.linux.tab2.feature2.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab2.feature3.title'),
              description: t('saudi-hosting.features.linux.tab2.feature3.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab2.feature4.title'),
              description: t('saudi-hosting.features.linux.tab2.feature4.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab2.feature5.title'),
              description: t('saudi-hosting.features.linux.tab2.feature5.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab2.feature6.title'),
              description: t('saudi-hosting.features.linux.tab2.feature6.description')
            },
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('saudi-hosting.features.linux.tab3.feature1.title'),
              description: t('saudi-hosting.features.linux.tab3.feature1.description')
            },
            
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('saudi-hosting.features.linux.tab4.feature1.title'),
              description: t('saudi-hosting.features.linux.tab4.feature1.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab4.feature2.title'),
              description: t('saudi-hosting.features.linux.tab4.feature2.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab4.feature3.title'),
              description: t('saudi-hosting.features.linux.tab4.feature3.description')
            },
            {
              title: t('saudi-hosting.features.linux.tab4.feature4.title'),
              description: t('saudi-hosting.features.linux.tab4.feature4.description')
            },
            
          ]
        }
      },
    ],
    
  ]

  const windowsFeaturesList = [
    [
      {
        panel: {
          features: [
            {
              title: t('saudi-hosting.features.windows.tab1.feature1.title'),
              description: t('saudi-hosting.features.windows.tab1.feature1.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab1.feature2.title'),
              description: t('saudi-hosting.features.windows.tab1.feature2.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab1.feature3.title'),
              description: t('saudi-hosting.features.windows.tab1.feature3.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab1.feature4.title'),
              description: t('saudi-hosting.features.windows.tab1.feature4.description')
            },
            
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('saudi-hosting.features.windows.tab2.feature1.title'),
              description: t('saudi-hosting.features.windows.tab2.feature1.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab2.feature2.title'),
              description: t('saudi-hosting.features.windows.tab2.feature2.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab2.feature3.title'),
              description: t('saudi-hosting.features.windows.tab2.feature3.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab2.feature4.title'),
              description: t('saudi-hosting.features.windows.tab2.feature4.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab2.feature5.title'),
              description: t('saudi-hosting.features.windows.tab2.feature5.description')
            },
          ]
        }
      },
    ],
    [
      {
        panel: {
          features: [
            {
              title: t('saudi-hosting.features.windows.tab3.feature1.title'),
              description: t('saudi-hosting.features.windows.tab3.feature1.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab3.feature2.title'),
              description: t('saudi-hosting.features.windows.tab3.feature2.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab3.feature3.title'),
              description: t('saudi-hosting.features.windows.tab3.feature3.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab3.feature4.title'),
              description: t('saudi-hosting.features.windows.tab3.feature4.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab3.feature5.title'),
              description: t('saudi-hosting.features.windows.tab3.feature5.description')
            },
            {
              title: t('saudi-hosting.features.windows.tab3.feature6.title'),
              description: t('saudi-hosting.features.windows.tab3.feature6.description')
            },
          ]
        }
      },
    ],
  ]

  const questions = [
    t('common.linux'),
    t('common.windows')
  ]

  return (
    <>
      <Helmet>
        <title>{t("saudi-hosting.meta.title")}</title>
      </Helmet>
      <MemoMainHostingSection 
        image={Bg}
        data={mainSectionData}
      />
      <MemoNestedTabs 
        title={t('saudi-hosting.plans.title')}
        subTitle={t('saudi-hosting.plans.subTitle')}
        golden
        mainTabs={[t('common.linux'), t('common.windows')]}
        nestedTabs={[t('saudi-hosting.plans.nestedTabs.tab1'), t('saudi-hosting.plans.nestedTabs.tab2'), t('saudi-hosting.plans.nestedTabs.tab3')]}
        plans={plans}
      /> 
      <FeaturesTabs
        title= {t('saudi-hosting.features.title')}
        subTitle= {t('saudi-hosting.features.subTitle')}
        description= {t('saudi-hosting.features.description')}
        mainTabList={featuresMainTabs}
        windowsTabList={featuresWindowsTabs}
        linuxTabList={featuresLinuxTabs}
        linuxData={featureList}
        windowsData={windowsFeaturesList}
        // tabsList={}
        data={featureList}
      />
      {/* <MemoFeaturesSection 
        data={featuresData}
      /> */}
      <MemoCollapseSection 
        title={t('saudi-hosting.questions.title')}
        subTitle={t('saudi-hosting.questions.subTitle')}
        tabsList={questions}
        path='saudi'
      />
    </>
  )
}

const MemoSaudiHosting = memo(SaudiHosting)

export default MemoSaudiHosting;