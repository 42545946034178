import React, { memo } from 'react'

type Props = {
  number?: string,
  title?: string
}

const CollapseTrigger = ({number, title}: Props) => {
  return (
    <div className='Collapsible__trigger' >
      <span className="number">0{number}</span>
      <p className="title">{title}</p>
    </div>
  )
}
const MemoCollapseTrigger = memo(CollapseTrigger)

export default MemoCollapseTrigger