import { memo, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SectionContent, SectionWrapper } from './NestedTabs.styled';
import MemoCommonButton from '../Common/CommonButton/CommonButton';
import { Col, Container, Row } from 'react-grid-system';
import { GrrenTitle, HeaderDescription, HeaderTitle} from '../../components/HomePage/SpecialSection/SpecialSection.styled';
import MemoMainPlanCard from '../MainPlanCard';

type Features = {
  title: string;
  content: string;
}

type PlansContent = {
  title: string;
  price: string;
  data: Features[];
  url: string;
}

type Data = {
  linux: {
    months: PlansContent[],
    year: PlansContent[],
    twoYears: PlansContent[],
  },
  windows: {
    months: PlansContent[],
    year: PlansContent[],
    twoYears: PlansContent[],
  }
}

// type plansTab = {
//   tab: PlansContent[]
// }

type Props = {
  title?: string;
  subTitle?: string;
  golden?: boolean;
  mainTabs: string[];
  nestedTabs: string[];
  plans?: Data;
}

const NestedTabs = ({title, subTitle, golden, mainTabs, nestedTabs, plans}: Props) => {
  return (
    <SectionWrapper>
      <SectionContent>
        <Container fluid>
          <Row>
            <Col xs={12} >
              <GrrenTitle>{title} </GrrenTitle>
              <HeaderTitle>{subTitle} </HeaderTitle>
              {/* <HeaderDescription>test</HeaderDescription> */}
            </Col>
            <Col xs={12} >
              <Tabs className='main_tabs' >
                <TabList>
                  {mainTabs.map((tab, index) => (
                    <Tab key={index}>
                      <MemoCommonButton name={tab} variant='rounded' />
                    </Tab>
                  ))}
                </TabList>
                <TabPanel>
                  <Tabs className='nested_tabs' >
                    <TabList>
                      {nestedTabs.map((tab, index) => (
                        <Tab key={index} >
                          <MemoCommonButton name={tab} variant='rounded' />
                        </Tab>
                      ))}
                    </TabList>

                    <TabPanel>
                      <Row>
                        {plans?.linux.months.map((plan, index) => (
                          <Col xl={3} lg={4} md={6} xs={12} >
                            <MemoMainPlanCard 
                              key={index}
                              title={plan.title} 
                              price={plan.price}
                              features={plan.data} 
                              golden={index === 1}
                              plan 
                              order='Order Now'
                            />
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                    <TabPanel>
                      <Row>
                        {plans?.linux.year.map((plan, index) => (
                          <Col xl={3} lg={4} md={6} xs={12} >
                            <MemoMainPlanCard 
                              key={index}
                              title={plan.title} 
                              price={plan.price}
                              features={plan.data} 
                              golden={index === 1}
                              plan 
                              order='Order Now'
                            />
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                    <TabPanel>
                      <Row>
                        {plans?.linux.twoYears.map((plan, index) => (
                          <Col xl={3} lg={4} md={6} xs={12} >
                            <MemoMainPlanCard 
                              key={index}
                              title={plan.title} 
                              price={plan.price}
                              features={plan.data} 
                              golden={index === 1}
                              plan 
                              order='Order Now'
                            />
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>

                  </Tabs>
                </TabPanel>

                <TabPanel>
                  <Tabs className='nested_tabs' >
                    <TabList>
                      {nestedTabs.map((tab, index) => (
                        <Tab key={index} >
                          <MemoCommonButton name={tab} variant='rounded' />
                        </Tab>
                      ))}
                    </TabList>

                    <TabPanel>
                      <Row>
                        {plans?.windows.months.map((plan, index) => (
                          <Col xl={3} lg={4} md={6} xs={12} >
                            <MemoMainPlanCard 
                              key={index}
                              title={plan.title} 
                              price={plan.price}
                              features={plan.data} 
                              golden={index === 1}
                              plan 
                              order='Order Now'
                            />
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                    <TabPanel>
                      <Row>
                        {plans?.windows.year.map((plan, index) => (
                          <Col xl={3} lg={4} md={6} xs={12} >
                            <MemoMainPlanCard 
                              key={index}
                              title={plan.title} 
                              price={plan.price}
                              features={plan.data} 
                              golden={index === 1}
                              plan 
                              order='Order Now'
                            />
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                    <TabPanel>
                      <Row>
                        {plans?.windows.twoYears.map((plan, index) => (
                          <Col xl={3} lg={4} md={6} xs={12} >
                            <MemoMainPlanCard 
                              key={index}
                              title={plan.title} 
                              price={plan.price}
                              features={plan.data} 
                              golden={index === 1}
                              plan 
                              order='Order Now'
                            />
                          </Col>
                        ))}
                      </Row>
                    </TabPanel>
                  </Tabs>
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </SectionContent>
    </SectionWrapper>
  )
}
const MemoNestedTabs = memo(NestedTabs);
export default MemoNestedTabs