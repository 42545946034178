import { lazy, memo } from 'react'
import { GrrenTitle, HeaderDescription, HeaderTitle, SectionContent, SectionWrapper } from './SpecialSection.styled';
import { Col, Container, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

const LazyMemoFeatureCard = lazy(() => import("../../FeatureCard"));

type Props = {
  white?: boolean;
}

const SpecialSection = ({white}: Props) => {
  const { t } = useTranslation();
  const translationPath = 'home.specialSection'
  return (
    <SectionWrapper white={white} >
      <SectionContent>
        <Container fluid>
          <Row>
            <Col xs={12} >
              <GrrenTitle>{t(`${translationPath}.title`)} </GrrenTitle>
              <HeaderTitle>{t(`${translationPath}.subTitle`)} </HeaderTitle>
              <HeaderDescription>{t(`${translationPath}.description`)}</HeaderDescription>
            </Col>
            <Col lg={3} md={4} sm={6} xs={12}>
              <LazyMemoFeatureCard 
                title={t(`${translationPath}.item1.title`)} 
                description={t(`${translationPath}.item1.description`)}
              />
            </Col>
            <Col lg={3} md={4} sm={6} xs={12}>
              <LazyMemoFeatureCard img />
            </Col>
            <Col lg={3} md={4} sm={6} xs={12}>
              <LazyMemoFeatureCard 
                title={t(`${translationPath}.item2.title`)} 
                description={t(`${translationPath}.item2.description`)}
              />
            </Col>
            <Col lg={3} md={4} sm={6} xs={12}>
              <LazyMemoFeatureCard 
                title={t(`${translationPath}.item3.title`)} 
                description={t(`${translationPath}.item3.description`)}
              />
            </Col>
            <Col lg={3} md={4} sm={6} xs={12}>
              <LazyMemoFeatureCard 
                title={t(`${translationPath}.item4.title`)} 
                description={t(`${translationPath}.item4.description`)}
              />
            </Col>
            <Col lg={3} md={4} sm={6} xs={12}>
              <LazyMemoFeatureCard 
                title={t(`${translationPath}.item5.title`)} 
                description={t(`${translationPath}.item5.description`)}
              />
            </Col>
            <Col md={6} sm={6} xs={12}>
              <LazyMemoFeatureCard img />
            </Col>
            
          </Row>
        </Container>
      </SectionContent>
    </SectionWrapper>
  )
}

const MemoSpecialSection = memo(SpecialSection);

export default MemoSpecialSection