import React, { memo } from 'react'
import { Copyrights, FooterBottom, FooterColumn, FooterIcons, FooterLink, FooterLogo, FooterWrapper, ListOfLinksWrapper, ListOfLogos, ListTitle, LogoItem, P } from './Footer.styled'
import { Container, Row, Col } from 'react-grid-system';
import LogoImg from '../../../images/Hosting.svg'
import YoutubeIcon from '../../../images/footer/ytfi.svg'
import TwitterIcon from '../../../images/footer/tffi.svg'
import LinkedinIcon from '../../../images/footer/lifi.svg'
import FacebookIcon from '../../../images/footer/fbfi.svg'
import Marketing from '../../../images/footer/Marketing.svg'
import Software from '../../../images/footer/Marketing.svg'


type Props = {}

const Footer = ({}: Props) => {
  return (
    <FooterWrapper>
      <Container fluid>
        <Row>
          <Col lg={3} >
            <FooterColumn>
              <FooterLogo src={LogoImg} alt="dimofinf" width={148} height={43}/>
              <P>
                Over the past 23 years, we've provided our expertise in digital transformation to more than 35,000 customers in cooperation with partnerships in Saudi Arabia ,aiming to achieve more success.
              </P>
            </FooterColumn>
          </Col>
          <Col lg={9} >
            <Row>
              <Col lg={2} md={4} xs={6} >
                <FooterColumn>
                  <ListTitle>
                    Information
                  </ListTitle>
                  <FooterLink to='/' >About Dimofinf</FooterLink>
                  <FooterLink to='/' >Our clients</FooterLink>
                  <FooterLink to='/' >Payment Methods</FooterLink>
                  <FooterLink to='/' >Our jobs</FooterLink>
                  <FooterLink to='/' >Knowledgebase</FooterLink>
                  <FooterLink to='/' >Privacy Policy</FooterLink>
                  <FooterLink to='/' >Terms of services</FooterLink>
                  <FooterLink to='/' >Management Levels</FooterLink>
                  <FooterLink to='/' >Blog</FooterLink>
                  <FooterLink to='/' >Store</FooterLink>
                </FooterColumn>
              </Col>
              <Col lg={2} md={4} xs={6} >
                <FooterColumn>
                  <ListTitle>
                    Company
                  </ListTitle>
                  <FooterLink to='/' >About Dimofinf</FooterLink>
                  <FooterLink to='/' >Our clients</FooterLink>
                  <FooterLink to='/' >Payment Methods</FooterLink>
                  <FooterLink to='/' >Our jobs</FooterLink>
                  <FooterLink to='/' >Knowledgebase</FooterLink>
                  <FooterLink to='/' >Privace Policy</FooterLink>
                  <FooterLink to='/' >Terms of services</FooterLink>
                </FooterColumn>
              </Col>
              <Col lg={3} md={4} xs={6} >
                <FooterColumn>
                  <ListTitle>
                    Hosting
                  </ListTitle>
                  <FooterLink to='/' >Domains</FooterLink>
                  <FooterLink to='/' >Web design & developing</FooterLink>
                  <FooterLink to='/' >Backup</FooterLink>
                  <FooterLink to='/' >SSL certificate</FooterLink>
                  <FooterLink to='/' >Resellar</FooterLink>
                  <FooterLink to='/' >Make money online</FooterLink>
                </FooterColumn>
              </Col>
              <Col lg={2} md={4} xs={6} >
                <FooterColumn>
                  <ListTitle>
                    Domains
                  </ListTitle>
                  <FooterLink to='/' >About Dimofinf</FooterLink>
                  <FooterLink to='/' >Our clients</FooterLink>
                  <FooterLink to='/' >Payment Methods</FooterLink>
                  <FooterLink to='/' >Our jobs</FooterLink>
                  <FooterLink to='/' >Knowledgebase</FooterLink>
                  <FooterLink to='/' >Privace Policy</FooterLink>
                  <FooterLink to='/' >Terms of services</FooterLink>
                  <FooterLink to='/' >Managment Levels</FooterLink>
                  <FooterLink to='/' >Blog</FooterLink>
                </FooterColumn>
              </Col>
              <Col lg={3} md={4} xs={6} >
                <FooterColumn>
                  <ListTitle>
                    Contact us
                  </ListTitle>
                  <FooterLink to='/' >+966 92 00 31132</FooterLink>
                  <FooterLink to='/' >Client area</FooterLink>
                  <FooterIcons>
                    <img src={YoutubeIcon} alt="YoutubeIcon" width={41} height={41} />
                    <img src={TwitterIcon} alt="TwitterIcon" width={41} height={41}/>
                    <img src={LinkedinIcon} alt="LinkedinIcon" width={41} height={41}/>
                    <img src={FacebookIcon} alt="FacebookIcon" width={41} height={41}/>
                  </FooterIcons>
                </FooterColumn>
              </Col>
            </Row>
            {/* <ListOfLinksWrapper>
              
            </ListOfLinksWrapper> */}
          </Col>
          <Col lg={12} >
            <FooterBottom>
              <Copyrights>
                Copyright © <span>dIMOFINF 2024</span> | All rights reserved. <span>Terms & Conditions | Privacy policy</span>             
              </Copyrights>
              <ListOfLogos>
                <LogoItem src={Marketing} alt="Marketing" width={136} height={43} />
                <LogoItem src={Software} alt="Software" width={136} height={43} />
                <LogoItem src={LogoImg}  alt="LogoImg" width={136} height={43} />
              </ListOfLogos>
            </FooterBottom>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  )
}

const MemoFooter = memo(Footer);
export default MemoFooter;