import styled, { css } from "styled-components";

export const Card = styled.div<{
  readonly img?: boolean;
  readonly star?: boolean;
}>`
  background-color: #fff;
  max-height: 312px;
  min-height: 312px;
  margin-bottom: 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin: 22px 0; */
  padding: ${props => props.img ? "0px" : "0px"};
  border-radius: 20px;
  overflow: ${props => props.img ? "hidden" : "visable"};
  position: relative;
 
  z-index: 10;
  &::before{
    position: absolute;
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -1;
    top: -1px;
    left: -1px;
    border-radius: 20px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 40%, rgba(0, 0, 0, 0.3))
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${({star}) => star && css`
    border-radius: 0;
    background-color: #FCFCFC;
    border-bottom-left-radius: 20px;
    transition: 0.3s ease all;
    min-height: 206px;
    max-height: unset;
    padding: 34px 18px; 
    &:hover{
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 0 4px 7px 3px #00000005;
    }
    &::before{
      display: none;
    }
  `}
`;

export const FeatureContent = styled.div`
  /* width: 100%; */
  height: 100%;
  min-height: 312px;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 22px;
`;

export const FeatureIcon  = styled.img`
  max-width: 46px;
  min-width: 46px;
  max-height: 46px;
  min-height: 46px;
  margin-bottom: 14px;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #000;
`;