import styled from 'styled-components'

export const CollapseWrapper = styled.div`
  .Collapsible{
    background-color: #fff;
    border-radius: 10px;
    padding: 18px 28px;
    margin-top: 26px;
    margin-bottom: 12px;
    max-width: 760px;
    .Collapsible__trigger {
      display: flex;
      cursor: pointer;
      align-items: center;
      font-weight: 400;
      text-decoration: none;
      position: relative;
      gap: 33px;
      color: #39414C;
      &:after {
        position: absolute;
        width: 44px;
        height: 44px;
        border: 1px solid #5F62624D;
        border-radius: 50%;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 44px;
        color: #09264A;
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        transition: transform 300ms;
      }
      .number{
        max-width: 44px;
        min-width: 44px;
        height: 44px;
        font-size: 16px;
        font-weight: 600;
        line-height: 44px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        background-color: #48988D;
      }
      &.is-open {
        &:after {
          content: '-';
        }
      }
      &.is-closed {
        &:after {
          content: '+';
        }
      }
    }
    .Collapsible__contentInner{
      max-width: 544px;
      padding: 0 80px;
      /* margin: auto; */
      font-size: 18px;
      line-height: 30px;
      font-weight: 300;
      color: #39414C;
    }
    @media(max-width: 1024px) {
      padding: 16px;
      .Collapsible__trigger {
        &::after{
          right: 0;
        }
      }
    }
    @media(max-width: 570px){
      .Collapsible__trigger{
        gap: 12px
      }
      .Collapsible__contentInner{
        padding: 10px 0 0 0;
      }
    }
  }
`;