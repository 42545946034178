import { Card, FeatureIcon, Title, Description, FeatureContent } from './FeatureCard.styled'
import Feature from "../../images/icons/feature_icon.svg"
import Star from "../../images/icons/star.svg"
import Img1 from '../../images/home/img1.png'
import Img2 from '../../images/home/img2.png'
import { memo } from 'react'
type Props = {
  img?: boolean;
  star?: boolean;
  title?: string;
  description?: string;
}

const FeatureCard = ({img, title, description, star}: Props) => {
  return (
    <Card img={img} star={star} >
      {img && (
        <img src={Img1 || Img2} alt='feature icon' width={100} height={100} />
      )}
      {star && (
        <>
          <FeatureIcon src={Star} alt="FeatureIcon" />
          {title && (
            <Title>
              {title}
            </Title>
          )}
          {description && (
            <Description>
              {description}
            </Description>
          )}
        </>
      )}
      {!img && !star && title && description && (
        <FeatureContent>
          <FeatureIcon src={Feature} alt="FeatureIcon" />
          <Title>
            {title}
          </Title>
          <Description>
            {description}
          </Description>
        </FeatureContent>
      )}
    </Card>
  )
}

const MemoFeatureCard = memo(FeatureCard);

export default MemoFeatureCard;