import styled, { css } from 'styled-components'

export const DotsWrapper = styled.div<{
  readonly height?: number;
  readonly position?: string;
  readonly variant?: string;
  readonly full?: boolean;
}>`
  position: ${props => props.position ? props.position : "absolute"};
  bottom: 0;
  left: ${props => props.variant === 'center' ? "50%" : props.variant === 'left' ? "16px" : "unset"};
  right: ${props => props.variant !== ("center" || "left") && "16px"};
  transform: translateX(${props => props.variant === "center" ? "-50%" : "unset"});
  min-width: 150px;
  height: ${props => props.height ? props.height : 100}vh;
  overflow: hidden;
  z-index: -1;
  max-width: 200px;
  ${({full}) => full && css`
    height: 100%;
  `}
  @media (max-width: 992px) {
    display: none;
  }
`

export const Dot = styled.div`
  width: 1px;
  height: 36px;
  border-radius: 40% 40% 75% 75%; /* Elliptical shape for water drop */
  background-color: rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(#00000030, #fff);
  /* background: rgb(0,0,0); */
  /* background-color: linear-gradient(rgba(0,0,0,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%); */
  position: absolute;
  bottom: -50px;
  animation: moveUp ease-in 5s infinite ;
  &:nth-child(1){
    left: 0%;
    animation-delay: 0.75s;
  }
  &:nth-child(2){
    left: 50%;
    animation-delay: 1.25s;
  }
  &:nth-child(3){
    left: 98%;
    animation-delay: 1.75s;
  }
`

export const Line = styled.div`
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0;
  &:nth-child(4){
    left: calc(0% + 1px);
  }
  &:nth-child(5){
    left: calc(50% + 1px);
  }
  &:nth-child(6){
    left: calc(98% + 1px);
  }
`;