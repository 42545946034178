import { Outlet } from 'react-router-dom'
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { AppWrapper } from './BaseLayout.styled';
import {memo, Suspense} from 'react';
import MemoFooter from '../../components/Common/Footer';
import MemoLoader from '../../components/Loader';
type Props = {}

function BaseLayout({}: Props) {
  const {t} = useTranslation()
  return (
    <Suspense fallback={<MemoLoader />} >
      <AppWrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("home.title")}</title>
        </Helmet>
        
        <Outlet />
        <MemoFooter />
      </AppWrapper>
    </Suspense>
  )
}

const MemoBaseLayout = memo(BaseLayout)

export default MemoBaseLayout