import styled from "styled-components";

export const SectionWrapper = styled.section`
  position: relative;
  overflow: hidden;
  .react-tabs__tab-list{
    display: flex;
    list-style: none;
    gap: 16px;
    margin: 20px 0;
  }
  .react-tabs__tab--selected{
    button{
      background-color: #48988D;
      color: #fff;
    }
  }
  @media(max-width: 570px) {
    .react-tabs__tab-list{
      flex-wrap: wrap;
    }
  }
`;

export const SectionShape = styled.img`
  position: absolute;
  top: 0;
  right: -65px;
  width: 755px;
  height: 493px;
`;

export const SectionContent = styled.div`
  max-width: 94vw;
  margin: auto;
`;

export const SectionData = styled.div`
  padding: 46px 0;
`;