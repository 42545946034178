import { memo } from 'react'
import LogoImg from '../../images/Hosting.svg'
import { LoaderWrapper } from './Loader.styled'

type Props = {}

const Loader = ({}: Props) => {
  return (
    <LoaderWrapper>
      <img src={LogoImg} alt="dimofinf"/>
    </LoaderWrapper>
  )
}

const MemoLoader = memo(Loader);

export default MemoLoader;