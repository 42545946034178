import React, { memo } from 'react'
import { Button } from './CommonButton.styled';

type Props = {
  name: string;
  variant?: string;
}

const CommonButton = ({name, variant}: Props) => {
  return (
    <Button className={variant} >{name}</Button>
  )
}

const MemoCommonButton = memo(CommonButton)

export default MemoCommonButton;