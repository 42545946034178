import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

type Props = {}

function About({}: Props) {
  const {t} = useTranslation()
  return (
    <Suspense fallback={<>loading ...</>}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("about.title")}</title>
        <meta name="description" content={t('about.desctiption')} />
      </Helmet>
      <h1>
        About
      </h1>
    </Suspense>
  )
}

const MemoAbout = React.memo(About)

export default MemoAbout;