import { memo } from 'react'
import { DotsWrapper, Dot, Line } from './AnimatedDots.styled'

type Props = {
  height?: number,
  position?: string,
  variant?: string,
  full?: boolean
}
// we need two states obsluted and also relative state 
const AnimatedDots = ({height, position, variant, full}: Props) => {
  return (
    <DotsWrapper height={height} position={position} variant={variant} full={full}>
      <Dot/>
      <Dot/>
      <Dot/>
      <Line/>
      <Line/>
      <Line/>
    </DotsWrapper>
  )
}

const MemoAnimatedDots = memo(AnimatedDots);

export default MemoAnimatedDots;