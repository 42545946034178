import { lazy, memo } from 'react'
import { ClientSliders, SectionWrapper, SlideTitle, P} from './ClientOpinionSection.styled';
import { Pagination, A11y } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

// Import Swiper styles
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const LazyMemoSlide = lazy(() => import("./Slide"));

const ClientOpinionSection = () => {
  const { t } = useTranslation();
  const translationPath = 'home.clientsSection'
  return (
    <SectionWrapper>
      <SlideTitle>{t(`${translationPath}.title`)}</SlideTitle>
      <P>{t(`${translationPath}.subTitle`)}</P>
      <ClientSliders
        modules={[Pagination, A11y]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        <SwiperSlide><LazyMemoSlide message={t(`${translationPath}.content1`)} /></SwiperSlide>
        <SwiperSlide><LazyMemoSlide message={t(`${translationPath}.content2`)} /></SwiperSlide>
        <SwiperSlide><LazyMemoSlide message={t(`${translationPath}.content3`)} /></SwiperSlide>
        <SwiperSlide><LazyMemoSlide message={t(`${translationPath}.content4`)} /></SwiperSlide>
        <SwiperSlide><LazyMemoSlide message={t(`${translationPath}.content5`)} /></SwiperSlide>
        <SwiperSlide><LazyMemoSlide message={t(`${translationPath}.content6`)} /></SwiperSlide>
        <SwiperSlide><LazyMemoSlide message={t(`${translationPath}.content7`)} /></SwiperSlide>
        <SwiperSlide><LazyMemoSlide message={t(`${translationPath}.content8`)} /></SwiperSlide>
      </ClientSliders>
    </SectionWrapper>
  )
}

const MemoClientOpinionSection = memo(ClientOpinionSection);

export default MemoClientOpinionSection;