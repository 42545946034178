import styled, {css} from 'styled-components'

export const CardIcon = styled.img`
  max-width: 84px;
  max-height: 84px;
  min-width: 84px;
  min-height: 84px;
  margin-bottom: 12px;
`;

export const CardTitle = styled.h4`
  font-size: 24px;
  font-weight: 700;
  line-height: 23px;
  color: #09264A;
  text-transform: capitalize;
  margin-bottom: 8px;
`;

export const Startat = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  color: #39414C;
  margin-bottom: 12px;
`;

export const PlanPrice = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 23px;
  color: #48988D;
  margin-bottom: 60px;
`

export const PlanItem = styled.p`
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  margin-bottom: 18px;
  color: #39414C;
  display: flex;
  align-items: center;
  gap: 12px;
  span {
    font-weight: 600;
  }
`;

export const OrderBtn = styled.button`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  padding: 12px 26px;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
  background-color: #48988D;
  color: #fff;
  margin-top: auto !important;
  width: 100%;
  height: 51px;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-align: center;
`;

export const Card = styled.div<{
  readonly active?: boolean;
  readonly plan?: boolean;
  readonly golden?: boolean;
}>`
  padding: 24px 16px;
  border-radius: 20px;
  box-shadow: 12px 12px 19px 0 #00000005;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: ${props => props.plan ? "763px" : "600px"};
  max-width: 424px;
  min-width: 408px;

  /* min-width: 424px; */
  margin: 20px 0;
  ${({active}) => active && css`
    svg {
      fill: #fff;
    }
    background-color: #48988D;
    * {
      color: #fff;
    }
    button{
      background-color: #fff;
      color: #48988D
    }
  `}
  ${({golden}) => golden && css`
    background-color: #D8AB34;
    * {
      color: #fff;
    }
    button{
      background-color: #fff;
      color: #D8AB34
    }
  `}
  ${({plan}) => plan && css`
    min-width: unset;
    max-width: unset;
  `}
  @media (max-width: 1200px) {
    min-width: unset;
  }
  @media (max-width: 570px) {
    min-width: unset;
    /* max-width: unset; */
  }
`;