import { memo, Suspense } from 'react'
import MemoLoader from '../../components/Loader'
import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom';
import MemoFooter from '../../components/Common/Footer';
import Navbar from '../../components/Common/Navbarr/Navbarr';

type Props = {
  title?: string;
  description?: string;
}

const Layout = ({title, description}: Props) => {
  return (
    <Suspense fallback={<MemoLoader />} >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Navbar />
      <Outlet />
      <MemoFooter />
    </Suspense>
  )
}

const MemoLayout = memo(Layout);
export default MemoLayout;