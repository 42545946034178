import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
// import { ThemeProvider } from 'react-bootstrap';

// import i18n from './i18n';
import MemoApp from './App';
import { BrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <MemoApp />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// function clickIt() {
  //   // Your code to perform the click action
  //   console.log('Click!'); // Example action
  // }
  
  // const minInterval = 8 * 60 * 1000; // 8 minutes in milliseconds
  // const maxInterval = 15 * 60 * 1000; // 15 minutes in milliseconds
  
  // const randomInterval = Math.floor(Math.random() * ((8 * 60 * 1000) - (15 * 60 * 1000) + 1)) + (8 * 60 * 1000);
  
  // const intervalId = setInterval(clickIt, randomInterval);