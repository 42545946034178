import styled from "styled-components";
// import 'react-tabs/style/react-tabs.css';

export const SectionWrapper = styled.section`
  padding: 42px 0 72px 0;
`;

export const SectionContent = styled.div`
  max-width: 94vw;
  margin: auto;
  .main_tabs{
    position: relative;
    margin-top: 26px;
  }
  .nested_tabs {
    .react-tabs__tab-list {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      gap: 20px;
    }
  }
  .react-tabs__tab{
    transition: 0.3s ease all;
    &:hover {
      button {
        background-color: #48988D;
        color: #fff;
      }
    }
  }
  .react-tabs__tab--selected{
    button{
      background-color: #48988D;
      color: #fff;
    }
  }
  .react-tabs__tab-list{
    list-style: none ;
    display: flex;
    gap: 16px;
    margin-bottom: 46px;
    padding: 6px 14px;
    background-color: #fff;
    border-radius: 10px;
    width: fit-content;
  }
  @media (max-width: 992px) {
    .react-tabs__tab {
      button {
        padding: 12px;
      }
    }
    .react-tabs__tab-list {
      gap: 12px;
      padding: 8px;
    }
    .nested_tabs {
      .react-tabs__tab-list {
        gap: 12px;
      }
    }
  }
  @media (max-width: 800px) {
    .react-tabs__tab-list{
      margin-bottom: 16px;
      gap: 8px;
      button {
        font-size: smaller;
        padding: 10px;
      }
    }
    .nested_tabs {
      .react-tabs__tab-list {
        position: relative;

      }
    }
  }
`;

