import styled from "styled-components";
import {Swiper} from "swiper/react";

export const SectionWrapper = styled.section`
  padding: 45px;
  background-color: #48988D;
  * {
    color: #fff;
  }
`;

export const ClientSliders = styled(Swiper)`
  .swiper-slide{
    
  }
  .swiper-pagination {
    position: relative;
    bottom: 0px !important;
  }
  .swiper-pagination-bullet{
    background-color: #fff;
  }
`;

export const SlideWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SlideTitle = styled.h4`
  font-size: 35px;
  font-weight: 700;
  line-height: 52.5px;
  text-align: center;
`;

export const P = styled.p`
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
  margin-bottom: 41px;
`;

export const ClientLogo = styled.div`
  width: 60px;
  height: 60px;
  margin-bottom: 18px;
  background-color: #fff;
  border-radius: 50%;
`;

export const SlideDescription = styled.p`
  max-width: 583px;
  margin-bottom: 32px;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
`;

