import { memo } from 'react'
import ServerIcon from "../../images/icons/server.svg"
import LayersIcon from "../../images/icons/layers.svg"
import DiamondIcon from "../../images/icons/diamond.svg"
import { Card, CardIcon, CardTitle, Startat, PlanPrice, PlanItem, OrderBtn } from './MainPlanCard.styled'

type Props = {
  active?: boolean;
  plan?: boolean;
  golden?: boolean;
  title?: string;
  startAt?: string;
  price?: string;
  feature1?: string;
  feature2?: string;
  feature3?: string;
  feature4?: string;
  order?: string;
  features?: {
    title: string;
    content: string;
  }[];
}

const MainPlanCard = ({active, golden, plan, title, startAt, features, price, feature1, feature2, feature3, feature4, order}: Props) => {
  return (
    <Card  plan={plan} golden={golden} active={active}>
      <CardIcon width={84} height={84} src={ active ? ServerIcon : golden ? DiamondIcon : LayersIcon} alt='LayersIcon'/>
      <CardTitle>{title}</CardTitle>
      <Startat>{startAt}</Startat>
      <PlanPrice>{price}</PlanPrice>
      {plan ? (
        <>
          {features?.map((item, index) => (
            <PlanItem key={index} > <span>{item.title}</span> {item.content}</PlanItem>
          ))}
          {/* <PlanItem> <span>Processor</span> Xeon E3-1230v2</PlanItem>
          <PlanItem> <span>RAM</span> 16 GB</PlanItem>
          <PlanItem> <span>HDD</span> 2 x 2TB SATA</PlanItem>
          <PlanItem> <span>Uplink</span> 1000 Mbit/sec</PlanItem>
          <PlanItem> <span>Traffic</span> 30TB</PlanItem>
          <PlanItem> <span>IPS</span> 1</PlanItem>
          <PlanItem> <span>RAID</span> Software</PlanItem>
          <PlanItem> <span>Management</span> Free</PlanItem> */}
        </>
      ) : (
        <>
          <PlanItem>{feature1}</PlanItem>
          <PlanItem>{feature2}</PlanItem>
          <PlanItem>{feature3}</PlanItem>
          <PlanItem>{feature4}</PlanItem>
        </>
      )}
      <OrderBtn>{order}</OrderBtn>
    </Card>
  )
}

const MemoMainPlanCard = memo(MainPlanCard);

export default MemoMainPlanCard;