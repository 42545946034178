import styled from "styled-components";
import { Link } from 'react-router-dom'

export const FooterWrapper = styled.div`
  padding: 42px 40px 0px 40px;
  margin-top: auto;
  display: flex;
  background-color: #fff;
  @media (max-width: 768px) {
    padding: 42px 0 0 0;
  }
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterLogo = styled.img`
  display: flex;
  min-width: 147.75px;
  max-width: 42.86px;
  margin-bottom: 30px;
`;

export const P = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: #39414C;
  line-height: 24px;
  text-align: start;
  margin: 0;
  width: 100%;
`;

export const ListOfLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const ListTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  margin-top: 0;
  /* text-align: left; */
  margin-bottom: 5px;
  color: #489884;
  position: relative;
  padding: 0 12px;
  &::before {
    position: absolute;
    content: '';
    background-color: #48988D;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    left: 0 ;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const FooterLink = styled(Link)`
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #39414C;
  text-decoration: none;
  /* text-align: left; */
`;

export const FooterBottom = styled.div`
  padding: 60px 0;
  margin-top: 24px;
  border-top: 1px solid #E4E4E4;
  display: flex; 
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    padding: 30px 0;
    flex-direction: column;
  }
`;

export const FooterIcons = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 94.77px;
  margin-top: 16px;
  svg, img {
    max-width: 40.79px;
    min-width: 40.79px;
    max-height: 40,79ex;
    min-height: 40,79ex;
  }
`;

export const ListOfLogos = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }
`;

export const Copyrights = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #09264A;
  span {
    font-weight: 600;
  }
  @media (max-width: 992px) {
    margin-bottom: 12px;
  }
  @media (max-width: 570px) {
    text-align: center;
  }
`;

export const LogoItem = styled.img`
  width: 136px;
  height: 42.86px;
`;