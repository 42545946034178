import React, { memo } from 'react'
import { DrawerWrapper, HeaderActionsWrapper, Li, NavLink, Ul } from './Navbarr.styled'
import MemoCommonButton from '../CommonButton/CommonButton'
import MemoLang from '../Language'

type Props = {
  isOpen?: boolean;
  setIsOpen?: () => void;
}

const Drawer = ({isOpen, setIsOpen}: Props) => {
  return (
    <DrawerWrapper isOpen={isOpen} onClick={setIsOpen} >
      <Ul>
        <Li>
          <NavLink to="/">
            Saudi Hosting 
          </NavLink>
        </Li>
        <Li>
          <NavLink to="/">
            Europe Hosting 
          </NavLink>
        </Li>
        <Li>
          <NavLink to="/">
            Cloud Servers 
          </NavLink>
        </Li>
        <Li>
          <NavLink to="/">
            Add on 
          </NavLink>
        </Li>
        <Li>
          <NavLink to="/">
            Email Exchange
          </NavLink>
        </Li>
      </Ul>
      <HeaderActionsWrapper>
        <MemoCommonButton name='Become Reseller' />
        <MemoCommonButton name='Open Ticket' variant='filled' />
        <MemoLang/> 
      </HeaderActionsWrapper>
    </DrawerWrapper>
  )
}

const MemoDrawer = memo(Drawer);
export default MemoDrawer;