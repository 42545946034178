import styled from "styled-components";

export const SectionWrapper = styled.section`
  padding: 58px 0 0 0;
  a{
    text-decoration: none;
  }
  .swiper-button-prev, .swiper-button-next{
    position: relative;
    left: unset;
    right: unset;
    top: 12px;
    bottom: unset;
    transform: unset;
    background-color: #48988D;
    transition: 0.3s ease all;
    border: 1px solid #48988D;
    &:hover {
      opacity: 1 !important;
      background-color: transparent;
      path {
        fill: #09264A;
      }
    }
    path{
      fill: #fff;
    }
    &:after{
      display: none;
    }
  }
  .swiper-button-disabled{
    opacity: 1 !important;
    border: 1px solid #48988D;
    background-color: transparent;
    path {
      fill: #09264A;
    }
  }
  .swiper-button-prev svg, .swiper-button-next svg{
    width: unset;
    height: unset;
  }
  .swiper-button-prev{
    transform: rotate(90deg);
  }
  .swiper-button-next{
    transform: rotate(-90deg);
  }
  .swiper-slide{
    min-width: 440px;
  }
  &:dir(rtl){
    .swiper-button-prev {
      transform: rotate(270deg);
    }
    .swiper-button-next {
      transform: rotate(90deg);
    }
  }
  @media (max-width: 570px) {
    .swiper-slide {
      min-width: unset;
    }
  }
`;

export const ArrowsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  height: 50px;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }
  svg{
    width: 12px;
    height: 12px;
  }
  @media (max-width: 570px){
    margin-top: 12px;
    align-self: flex-end;
    
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 46px 0;
  max-width: 78vw;
  @media (max-width: 768px) {
    max-width: unset;
  }
  @media (max-width: 570px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

export const HeaderContent = styled.div`
  max-width: 648px;
`;

export const GrrenTitle = styled.h5`
  font-size: 12px;
  font-weight: 500;
  line-height: 40px;
  color: #48988D;
`;

export const HeaderTitle = styled.h4`
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #000;
`;

export const HeaderDescription = styled.p`
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  color: #39414C;
`;