import React, { memo } from 'react'
import { SectionContent, SectionData, SectionWrapper } from './FeaturesSection.styled'
import { Col, Container, Row } from 'react-grid-system'
import { GrrenTitle, HeaderDescription, HeaderTitle} from '../../components/HomePage/SpecialSection/SpecialSection.styled';
import MemoAnimatedDots from '../Common/AnimatedDots.tsx';
import MemoFeatureCard from '../FeatureCard';

type Feature = {
  id: number;
  title?: string;
  description?: string;
  star?: boolean;
  img?: boolean;
}

type Props = {
  
  data: { 
    title?: string;
    subTitle?: string;
    description?: string;
    action?: string;
    cards: Feature[]
  };
}

const FeaturesSection = ({data}: Props) => {
  return (
    <SectionWrapper>
      <SectionContent>
        <Container fluid>
          <Row>
            <Col lg={2} md={12}>
              <MemoAnimatedDots full position='relative' variant='left' />
            </Col>
            <Col xs={12} md={10} >
              <SectionData>
                <GrrenTitle>{data.title} </GrrenTitle>
                <HeaderTitle>{data.subTitle} </HeaderTitle>
                <HeaderDescription>{data.description}</HeaderDescription>
                <Row>
                  {data.cards.map((card) => (
                    <Col xl={4} lg={4} md={6} xs={12} key={card.id} >
                      <MemoFeatureCard 
                        star={card.star}
                        img={card.img}
                        title={card.title} 
                        description={card.description}
                      />
                    </Col>
                  ))}
                  {data.action && (
                    <Col xs={12}>
                      {data.action}
                    </Col>
                  )}
                </Row>
              </SectionData>
            </Col>
          </Row>
        </Container>
      </SectionContent>
    </SectionWrapper>
  )
}

const MemoFeaturesSection = memo(FeaturesSection)

export default MemoFeaturesSection;