import React, { memo } from 'react'
import MemoMainHostingSection from '../../components/MainHostingSection/MainHostingSection'
import Bg from "../../images/bg.webp";
import CloudIcon from "../../images/icons/cloud.svg"
import MemoMiniPlansSection from '../../components/MiniPlansSection/MiniPlansSection';
import MemoFeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import Table from '../../components/Table/Table';
import { useTranslation } from 'react-i18next';
type Props = {}



const CloudHosting = ({}: Props) => {
  const { t } = useTranslation();
  const translationPath = 'cloud-hosting'
  const data = {
    greentitle: t(`${translationPath}.plans.title`),
    title: t(`${translationPath}.plans.subTitle`),
    description: t(`${translationPath}.plans.description`),
    cards: [
      {
        id: 1,
        padge: 'X1',
        icon: CloudIcon,
        title: t(`${translationPath}.plans.plan1.title`),
        price: t(`${translationPath}.plans.plan1.price`),
        features: [
          {
            item: t(`${translationPath}.plans.plan1.features.item1.title`),
            content: t(`${translationPath}.plans.plan1.features.item1.content`)
          },
          {
            item: t(`${translationPath}.plans.plan1.features.item2.title`),
            content: t(`${translationPath}.plans.plan1.features.item2.content`)
          },
          {
            item: t(`${translationPath}.plans.plan1.features.item3.title`),
            content: t(`${translationPath}.plans.plan1.features.item3.content`)
          },
          {
            item: t(`${translationPath}.plans.plan1.features.item4.title`),
            content: t(`${translationPath}.plans.plan1.features.item4.content`)
          },
        ],
        url: '/'
      },
      {
        id: 2,
        padge: 'X2',
        icon: CloudIcon,
        title: t(`${translationPath}.plans.plan2.title`),
        price: t(`${translationPath}.plans.plan2.price`),
        features: [
          {
            item: t(`${translationPath}.plans.plan2.features.item1.title`),
            content: t(`${translationPath}.plans.plan2.features.item1.content`)
          },
          {
            item: t(`${translationPath}.plans.plan2.features.item2.title`),
            content: t(`${translationPath}.plans.plan2.features.item2.content`)
          },
          {
            item: t(`${translationPath}.plans.plan2.features.item3.title`),
            content: t(`${translationPath}.plans.plan2.features.item3.content`)
          },
          {
            item: t(`${translationPath}.plans.plan2.features.item4.title`),
            content: t(`${translationPath}.plans.plan2.features.item4.content`)
          },
        ],
        url: '/'
      },
      {
        id: 3,
        padge: 'X3',
        icon: CloudIcon,
        title: t(`${translationPath}.plans.plan3.title`),
        price: t(`${translationPath}.plans.plan3.price`),
        features: [
          {
            item: t(`${translationPath}.plans.plan3.features.item1.title`),
            content: t(`${translationPath}.plans.plan3.features.item1.content`)
          },
          {
            item: t(`${translationPath}.plans.plan3.features.item2.title`),
            content: t(`${translationPath}.plans.plan3.features.item2.content`)
          },
          {
            item: t(`${translationPath}.plans.plan3.features.item3.title`),
            content: t(`${translationPath}.plans.plan3.features.item3.content`)
          },
          {
            item: t(`${translationPath}.plans.plan3.features.item4.title`),
            content: t(`${translationPath}.plans.plan3.features.item4.content`)
          },
        ],
        url: '/'
      },
      {
        id: 4,
        padge: 'X4',
        icon: CloudIcon,
        title: t(`${translationPath}.plans.plan4.title`),
        price: t(`${translationPath}.plans.plan4.price`),
        features: [
          {
            item: t(`${translationPath}.plans.plan4.features.item1.title`),
            content: t(`${translationPath}.plans.plan4.features.item1.content`)
          },
          {
            item: t(`${translationPath}.plans.plan4.features.item2.title`),
            content: t(`${translationPath}.plans.plan4.features.item2.content`)
          },
          {
            item: t(`${translationPath}.plans.plan4.features.item3.title`),
            content: t(`${translationPath}.plans.plan4.features.item3.content`)
          },
          {
            item: t(`${translationPath}.plans.plan4.features.item4.title`),
            content: t(`${translationPath}.plans.plan4.features.item4.content`)
          },
        ],
        url: '/'
      },
      {
        id: 5,
        padge: 'X5',
        icon: CloudIcon,
        title: t(`${translationPath}.plans.plan5.title`),
        price: t(`${translationPath}.plans.plan5.price`),
        features: [
          {
            item: t(`${translationPath}.plans.plan5.features.item1.title`),
            content: t(`${translationPath}.plans.plan5.features.item1.content`)
          },
          {
            item: t(`${translationPath}.plans.plan5.features.item2.title`),
            content: t(`${translationPath}.plans.plan5.features.item2.content`)
          },
          {
            item: t(`${translationPath}.plans.plan5.features.item3.title`),
            content: t(`${translationPath}.plans.plan5.features.item3.content`)
          },
          {
            item: t(`${translationPath}.plans.plan5.features.item4.title`),
            content: t(`${translationPath}.plans.plan5.features.item4.content`)
          },
        ],
        url: '/'
      },
      {
        id: 6,
        padge: 'X6',
        icon: CloudIcon,
        title: t(`${translationPath}.plans.plan6.title`),
        price: t(`${translationPath}.plans.plan6.price`),
        features: [
          {
            item: t(`${translationPath}.plans.plan6.features.item1.title`),
            content: t(`${translationPath}.plans.plan6.features.item1.content`)
          },
          {
            item: t(`${translationPath}.plans.plan6.features.item2.title`),
            content: t(`${translationPath}.plans.plan6.features.item2.content`)
          },
          {
            item: t(`${translationPath}.plans.plan6.features.item3.title`),
            content: t(`${translationPath}.plans.plan6.features.item3.content`)
          },
          {
            item: t(`${translationPath}.plans.plan6.features.item4.title`),
            content: t(`${translationPath}.plans.plan6.features.item4.content`)
          },
        ],
        url: '/'
      },
      
    ]
  }

  const featuresData = {
    title: t(`${translationPath}.features.title`),
    subTitle: t(`${translationPath}.features.subTitle`),
    // description: 'We always aim at providing the best web hosting at the least prices as Dimofinf has its own servers which .',
    action:'',
    cards: [
      {
        id: 1,
        title: t(`${translationPath}.features.feature1.title`),
        description: t(`${translationPath}.features.feature1.description`),
        star: true,
        img: false,
      },
      {
        id: 2,
        title: t(`${translationPath}.features.feature2.title`),
        description: t(`${translationPath}.features.feature2.description`),
        star: true,
        img: false,
      },
      {
        id: 3,
        title: t(`${translationPath}.features.feature3.title`),
        description: t(`${translationPath}.features.feature3.description`),
        star: true,
        img: false,
      },
      {
        id: 4,
        title: t(`${translationPath}.features.feature4.title`),
        description: t(`${translationPath}.features.feature4.description`),
        star: true,
        img: false,
      },
      {
        id: 5,
        title: t(`${translationPath}.features.feature5.title`),
        description: t(`${translationPath}.features.feature5.description`),
        star: true,
        img: false,
      },
      {
        id: 6,
        title: t(`${translationPath}.features.feature6.title`),
        description: t(`${translationPath}.features.feature6.description`),
        star: true,
        img: false,
      },
      {
        id: 7,
        title: t(`${translationPath}.features.feature7.title`),
        description: t(`${translationPath}.features.feature7.description`),
        star: true,
        img: false,
      },
      {
        id: 8,
        title: t(`${translationPath}.features.feature8.title`),
        description: t(`${translationPath}.features.feature8.description`),
        star: true,
        img: false,
      },
      {
        id: 9,
        title: t(`${translationPath}.features.feature9.title`),
        description: t(`${translationPath}.features.feature9.description`),
        star: true,
        img: false,
      },
      {
        id: 10,
        title: t(`${translationPath}.features.feature10.title`),
        description: t(`${translationPath}.features.feature10.description`),
        star: true,
        img: false,
      },
      
    ]
  }

  const mainSectionData = [
    {
      title: t(`${translationPath}.hero.title`),
      subTitle: t(`${translationPath}.hero.subTitle`),
      description: t(`${translationPath}.hero.description`),
      url: "/"
    },
    
  ]

  const tableData = [
    [ `${t(`${translationPath}.table.row1.item1`)}`,
      `${t(`${translationPath}.table.row1.item2`)}`,
      `${t(`${translationPath}.table.row1.item3`)}`,
      `${t(`${translationPath}.table.row1.item4`)}`,
      `${t(`${translationPath}.table.row1.item5`)}`,
      `${t(`${translationPath}.table.row1.item6`)}`,
      `${t(`${translationPath}.table.row1.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row2.item1`)}`,
      `${t(`${translationPath}.table.row2.item2`)}`,
      `${t(`${translationPath}.table.row2.item3`)}`,
      `${t(`${translationPath}.table.row2.item4`)}`,
      `${t(`${translationPath}.table.row2.item5`)}`,
      `${t(`${translationPath}.table.row2.item6`)}`,
      `${t(`${translationPath}.table.row2.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row3.item1`)}`,
      `${t(`${translationPath}.table.row3.item2`)}`,
      `${t(`${translationPath}.table.row3.item3`)}`,
      `${t(`${translationPath}.table.row3.item4`)}`,
      `${t(`${translationPath}.table.row3.item5`)}`,
      `${t(`${translationPath}.table.row3.item6`)}`,
      `${t(`${translationPath}.table.row3.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row4.item1`)}`,
      `${t(`${translationPath}.table.row4.item2`)}`,
      `${t(`${translationPath}.table.row4.item3`)}`,
      `${t(`${translationPath}.table.row4.item4`)}`,
      `${t(`${translationPath}.table.row4.item5`)}`,
      `${t(`${translationPath}.table.row4.item6`)}`,
      `${t(`${translationPath}.table.row4.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row5.item1`)}`,
      `${t(`${translationPath}.table.row5.item2`)}`,
      `${t(`${translationPath}.table.row5.item3`)}`,
      `${t(`${translationPath}.table.row5.item4`)}`,
      `${t(`${translationPath}.table.row5.item5`)}`,
      `${t(`${translationPath}.table.row5.item6`)}`,
      `${t(`${translationPath}.table.row5.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row6.item1`)}`,
      `${t(`${translationPath}.table.row6.item2`)}`,
      `${t(`${translationPath}.table.row6.item3`)}`,
      `${t(`${translationPath}.table.row6.item4`)}`,
      `${t(`${translationPath}.table.row6.item5`)}`,
      `${t(`${translationPath}.table.row6.item6`)}`,
      `${t(`${translationPath}.table.row6.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row7.item1`)}`,
      `${t(`${translationPath}.table.row7.item2`)}`,
      `${t(`${translationPath}.table.row7.item3`)}`,
      `${t(`${translationPath}.table.row7.item4`)}`,
      `${t(`${translationPath}.table.row7.item5`)}`,
      `${t(`${translationPath}.table.row7.item6`)}`,
      `${t(`${translationPath}.table.row7.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row8.item1`)}`,
      `${t(`${translationPath}.table.row8.item2`)}`,
      `${t(`${translationPath}.table.row8.item3`)}`,
      `${t(`${translationPath}.table.row8.item4`)}`,
      `${t(`${translationPath}.table.row8.item5`)}`,
      `${t(`${translationPath}.table.row8.item6`)}`,
      `${t(`${translationPath}.table.row8.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row9.item1`)}`,
      `${t(`${translationPath}.table.row9.item2`)}`,
      `${t(`${translationPath}.table.row9.item3`)}`,
      `${t(`${translationPath}.table.row9.item4`)}`,
      `${t(`${translationPath}.table.row9.item5`)}`,
      `${t(`${translationPath}.table.row9.item6`)}`,
      `${t(`${translationPath}.table.row9.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row10.item1`)}`,
      `${t(`${translationPath}.table.row10.item2`)}`,
      `${t(`${translationPath}.table.row10.item3`)}`,
      `${t(`${translationPath}.table.row10.item4`)}`,
      `${t(`${translationPath}.table.row10.item5`)}`,
      `${t(`${translationPath}.table.row10.item6`)}`,
      `${t(`${translationPath}.table.row10.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row11.item1`)}`,
      `${t(`${translationPath}.table.row11.item2`)}`,
      `${t(`${translationPath}.table.row11.item3`)}`,
      `${t(`${translationPath}.table.row11.item4`)}`,
      `${t(`${translationPath}.table.row11.item5`)}`,
      `${t(`${translationPath}.table.row11.item6`)}`,
      `${t(`${translationPath}.table.row11.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row12.item1`)}`,
      `${t(`${translationPath}.table.row12.item2`)}`,
      `${t(`${translationPath}.table.row12.item3`)}`,
      `${t(`${translationPath}.table.row12.item4`)}`,
      `${t(`${translationPath}.table.row12.item5`)}`,
      `${t(`${translationPath}.table.row12.item6`)}`,
      `${t(`${translationPath}.table.row12.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row13.item1`)}`,
      `${t(`${translationPath}.table.row13.item2`)}`,
      `${t(`${translationPath}.table.row13.item3`)}`,
      `${t(`${translationPath}.table.row13.item4`)}`,
      `${t(`${translationPath}.table.row13.item5`)}`,
      `${t(`${translationPath}.table.row13.item6`)}`,
      `${t(`${translationPath}.table.row13.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row14.item1`)}`,
      `${t(`${translationPath}.table.row14.item2`)}`,
      `${t(`${translationPath}.table.row14.item3`)}`,
      `${t(`${translationPath}.table.row14.item4`)}`,
      `${t(`${translationPath}.table.row14.item5`)}`,
      `${t(`${translationPath}.table.row14.item6`)}`,
      `${t(`${translationPath}.table.row14.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row15.item1`)}`,
      `${t(`${translationPath}.table.row15.item2`)}`,
      `${t(`${translationPath}.table.row15.item3`)}`,
      `${t(`${translationPath}.table.row15.item4`)}`,
      `${t(`${translationPath}.table.row15.item5`)}`,
      `${t(`${translationPath}.table.row15.item6`)}`,
      `${t(`${translationPath}.table.row15.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row16.item1`)}`,
      `${t(`${translationPath}.table.row16.item2`)}`,
      `${t(`${translationPath}.table.row16.item3`)}`,
      `${t(`${translationPath}.table.row16.item4`)}`,
      `${t(`${translationPath}.table.row16.item5`)}`,
      `${t(`${translationPath}.table.row16.item6`)}`,
      `${t(`${translationPath}.table.row16.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row17.item1`)}`,
      `${t(`${translationPath}.table.row17.item2`)}`,
      `${t(`${translationPath}.table.row17.item3`)}`,
      `${t(`${translationPath}.table.row17.item4`)}`,
      `${t(`${translationPath}.table.row17.item5`)}`,
      `${t(`${translationPath}.table.row17.item6`)}`,
      `${t(`${translationPath}.table.row17.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row18.item1`)}`,
      `${t(`${translationPath}.table.row18.item2`)}`,
      `${t(`${translationPath}.table.row18.item3`)}`,
      `${t(`${translationPath}.table.row18.item4`)}`,
      `${t(`${translationPath}.table.row18.item5`)}`,
      `${t(`${translationPath}.table.row18.item6`)}`,
      `${t(`${translationPath}.table.row18.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row19.item1`)}`,
      `${t(`${translationPath}.table.row19.item2`)}`,
      `${t(`${translationPath}.table.row19.item3`)}`,
      `${t(`${translationPath}.table.row19.item4`)}`,
      `${t(`${translationPath}.table.row19.item5`)}`,
      `${t(`${translationPath}.table.row19.item6`)}`,
      `${t(`${translationPath}.table.row19.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row20.item1`)}`,
      `${t(`${translationPath}.table.row20.item2`)}`,
      `${t(`${translationPath}.table.row20.item3`)}`,
      `${t(`${translationPath}.table.row20.item4`)}`,
      `${t(`${translationPath}.table.row20.item5`)}`,
      `${t(`${translationPath}.table.row20.item6`)}`,
      `${t(`${translationPath}.table.row20.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row21.item1`)}`,
      `${t(`${translationPath}.table.row21.item2`)}`,
      `${t(`${translationPath}.table.row21.item3`)}`,
      `${t(`${translationPath}.table.row21.item4`)}`,
      `${t(`${translationPath}.table.row21.item5`)}`,
      `${t(`${translationPath}.table.row21.item6`)}`,
      `${t(`${translationPath}.table.row21.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row22.item1`)}`,
      `${t(`${translationPath}.table.row22.item2`)}`,
      `${t(`${translationPath}.table.row22.item3`)}`,
      `${t(`${translationPath}.table.row22.item4`)}`,
      `${t(`${translationPath}.table.row22.item5`)}`,
      `${t(`${translationPath}.table.row22.item6`)}`,
      `${t(`${translationPath}.table.row22.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row23.item1`)}`,
      `${t(`${translationPath}.table.row23.item2`)}`,
      `${t(`${translationPath}.table.row23.item3`)}`,
      `${t(`${translationPath}.table.row23.item4`)}`,
      `${t(`${translationPath}.table.row23.item5`)}`,
      `${t(`${translationPath}.table.row23.item6`)}`,
      `${t(`${translationPath}.table.row23.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row24.item1`)}`,
      `${t(`${translationPath}.table.row24.item2`)}`,
      `${t(`${translationPath}.table.row24.item3`)}`,
      `${t(`${translationPath}.table.row24.item4`)}`,
      `${t(`${translationPath}.table.row24.item5`)}`,
      `${t(`${translationPath}.table.row24.item6`)}`,
      `${t(`${translationPath}.table.row24.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row25.item1`)}`,
      `${t(`${translationPath}.table.row25.item2`)}`,
      `${t(`${translationPath}.table.row25.item3`)}`,
      `${t(`${translationPath}.table.row25.item4`)}`,
      `${t(`${translationPath}.table.row25.item5`)}`,
      `${t(`${translationPath}.table.row25.item6`)}`,
      `${t(`${translationPath}.table.row25.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row26.item1`)}`,
      `${t(`${translationPath}.table.row26.item2`)}`,
      `${t(`${translationPath}.table.row26.item3`)}`,
      `${t(`${translationPath}.table.row26.item4`)}`,
      `${t(`${translationPath}.table.row26.item5`)}`,
      `${t(`${translationPath}.table.row26.item6`)}`,
      `${t(`${translationPath}.table.row26.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row27.item1`)}`,
      `${t(`${translationPath}.table.row27.item2`)}`,
      `${t(`${translationPath}.table.row27.item3`)}`,
      `${t(`${translationPath}.table.row27.item4`)}`,
      `${t(`${translationPath}.table.row27.item5`)}`,
      `${t(`${translationPath}.table.row27.item6`)}`,
      `${t(`${translationPath}.table.row27.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row28.item1`)}`,
      `${t(`${translationPath}.table.row28.item2`)}`,
      `${t(`${translationPath}.table.row28.item3`)}`,
      `${t(`${translationPath}.table.row28.item4`)}`,
      `${t(`${translationPath}.table.row28.item5`)}`,
      `${t(`${translationPath}.table.row28.item6`)}`,
      `${t(`${translationPath}.table.row28.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row29.item1`)}`,
      `${t(`${translationPath}.table.row29.item2`)}`,
      `${t(`${translationPath}.table.row29.item3`)}`,
      `${t(`${translationPath}.table.row29.item4`)}`,
      `${t(`${translationPath}.table.row29.item5`)}`,
      `${t(`${translationPath}.table.row29.item6`)}`,
      `${t(`${translationPath}.table.row29.item7`)}`,
    ],
    [ `${t(`${translationPath}.table.row30.item1`)}`,
      `${t(`${translationPath}.table.row30.item2`)}`,
      `${t(`${translationPath}.table.row30.item3`)}`,
      `${t(`${translationPath}.table.row30.item4`)}`,
      `${t(`${translationPath}.table.row30.item5`)}`,
      `${t(`${translationPath}.table.row30.item6`)}`,
      `${t(`${translationPath}.table.row30.item7`)}`,
    ],
  ];

  return (
    <>
      <MemoMainHostingSection 
        image={Bg}
        data={mainSectionData}
      />
      <MemoMiniPlansSection data={data} />
      <MemoFeaturesSection 
        data={featuresData}
      /> 
      <Table title={t(`${translationPath}.table.title`)} tableData={tableData} />
    </>
  )
}

const MemoCloudHosting = memo(CloudHosting)

export default MemoCloudHosting