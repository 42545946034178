import styled from "styled-components";
import { DotsWrapper } from "../Common/AnimatedDots.tsx/AnimatedDots.styled";
import { HeaderDescription } from "../HomePage/SpecialSection/SpecialSection.styled";

export const SectionWrapper = styled.section`
  background-color: #fff;
  ${DotsWrapper} {
    z-index: 0;
  }
  ${HeaderDescription}{
    margin-bottom: 0px;
  }
  .react-tabs__tab-list{
    display: flex;
    list-style: none;
    gap: 16px;
    margin-bottom: 30px;
  }
  .react-tabs__tab--selected{
    button{
      background-color: #48988D;
      color: #fff;
    }
  }
  @media(max-width: 570px) {
    .react-tabs__tab-list{
      flex-wrap: wrap;
    }
  }
`;

export const SectionContent = styled.div`
  max-width: 94vw;
  margin: auto;
`;

export const SectionData = styled.div`
  padding: 90px 0 20px 0;
`;