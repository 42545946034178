import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MemoCommonButton from '../Common/CommonButton/CommonButton';
import MemoFeatureCard from '../FeatureCard';
import { SectionContent, SectionData, SectionWrapper } from './FeaturesTabs.styled'
import { Col, Container, Row } from 'react-grid-system'
import { GrrenTitle, HeaderDescription, HeaderTitle} from '../../components/HomePage/SpecialSection/SpecialSection.styled';
import MemoAnimatedDots from '../Common/AnimatedDots.tsx';
type Feature = {
  title?: string;
  description?: string;
}
type Features = {
  features: Feature[];
}
type PanelList = {
  panel: Features, 
}

type Props = {
  title: string;
  subTitle: string;
  description: string;
  data: PanelList[][];
  mainTabList: string[];
  tabsList?: string[];
  windowsTabList: string[]
  linuxTabList: string[]
  linuxData?: PanelList[][]
  windowsData?: PanelList[][]
}

const FeaturesTabs = ({title, subTitle, description, linuxData, windowsData, data, mainTabList, tabsList, windowsTabList, linuxTabList}: Props) => {
  console.log(data, linuxData, windowsData);
  
  return (
    <SectionWrapper>
      <SectionContent>
        <Container fluid>
          <Row>
            <Col lg={2} md={12}>
              <MemoAnimatedDots full position='relative' variant='left' />
            </Col>
            <Col lg={10} md={12} xs={12} >
              <SectionData>
                <GrrenTitle>{title} </GrrenTitle>
                <HeaderTitle>{subTitle} </HeaderTitle>
                <HeaderDescription>{description}</HeaderDescription>
              </SectionData>
              <Tabs>
                <TabList>
                  {mainTabList.map((tab, index) => (
                    <Tab key={index}>
                      <MemoCommonButton name={tab} variant='rounded' />
                    </Tab> 
                  ))}
                </TabList>
                <TabPanel>
                  <Tabs className='main_tabs' >
                    <TabList>
                      {linuxTabList.map((tab) => (
                        <Tab key={tab}>
                          <MemoCommonButton name={tab} variant='rounded' />
                        </Tab> 
                      ))}
                    </TabList>
                    {data?.map((panel, index) => (
                      <TabPanel key={index}>
                        <Row>
                          {panel[0].panel.features.map((item, index) => (
                            <Col lg={4} md={6} xs={12} key={index} >
                              <MemoFeatureCard 
                                star
                                title={item.title}
                                description={item.description}
                              />
                            </Col>
                          ))}
                        </Row>
                      </TabPanel>
                    ))}
                  </Tabs>
                </TabPanel>
                <TabPanel>
                  <Tabs className='main_tabs' >
                    <TabList>
                      {windowsTabList.map((tab) => (
                        <Tab key={tab}>
                          <MemoCommonButton name={tab} variant='rounded' />
                        </Tab> 
                      ))}
                    </TabList>
                    {windowsData?.map((panel, index) => (
                      <TabPanel key={index}>
                        <Row>
                          {panel[0]?.panel.features.map((item, index) => (
                            <Col lg={4} md={6} xs={12} key={index} >
                              <MemoFeatureCard 
                                star
                                title={item.title}
                                description={item.description}
                              />
                            </Col>
                          ))}
                        </Row>
                      </TabPanel>
                    ))}
                  </Tabs>
                </TabPanel>
                
              </Tabs>
              
            </Col>
          </Row>
        </Container>
      </SectionContent>
    </SectionWrapper>
  )
}

export default FeaturesTabs