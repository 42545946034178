import { memo } from 'react'
import { CollapseWrapper } from './Collapse.styled'
import Collapsible from 'react-collapsible';
import MemoCollapseTrigger from './CollapseTrigger';
import { useTranslation } from 'react-i18next';





type Props = {
  path: string;
}

const Collapse = ({path}: Props) => {
  const { t } = useTranslation();
  const data = [
    {
      id: "1",
      title: t(`${path}.ask1.question`),
      description: t(`${path}.ask1.answer`),
    },
    {
      id: "2",
      title: t(`${path}.ask2.question`),
      description: t(`${path}.ask2.answer`),
    },
    {
      id: "3",
      title: t(`${path}.ask3.question`),
      description: t(`${path}.ask3.answer`),
    },
    {
      id: "4",
      title: t(`${path}.ask4.question`),
      description: t(`${path}.ask4.answer`),
    },
  ]
  return (

    <CollapseWrapper>
      {data.map((item) => (
        <Collapsible trigger={<MemoCollapseTrigger number={item.id} title={item.title} />} key={item.id} open={item.id === "2"}>
          {item.description}
        </Collapsible>
      ))}
    </CollapseWrapper>
  )
}

const MemoCollapse = memo(Collapse)

export default MemoCollapse