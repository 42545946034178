import styled, { css } from "styled-components";

export const SectionWrapper = styled.section<{
  readonly white?: boolean;
}>`
  padding: 66px 0;
  ${({white}) => white && css`
    background-color: #fff;
  `}
  /* height: 100vh; */
`;

export const SectionContent = styled.div`
  max-width: 95vw;
  margin: auto;
`;

export const GrrenTitle = styled.h5`
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  color: #48988D;
`;

export const HeaderTitle = styled.h4`
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #000;
`;

export const HeaderDescription = styled.p`
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  color: #39414C;
  max-width: 475px;
`;