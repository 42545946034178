// import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "./CommonButton/CommonButton.styled";
import { memo } from "react";
// import { StyledIcon } from "../../../StyledComponents/ControlsIcon/StyledIcon";

type Props = {};

const Language = (props: Props) => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
    window.location.reload();
  };
  
  return (
    <Button className="filled" onClick={handleChangeLanguage}>
      {i18n.language === "en" ? "AR" : "EN"}
    </Button>
  );
};

const MemoLang = memo(Language)

export default MemoLang;
