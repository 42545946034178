import { memo } from 'react'
import { GrrenTitle, HeaderTitle, SectionContent, SectionData, SectionWrapper } from './MiniPlanSection.styled';
import { Col, Container, Row } from 'react-grid-system'
import MemoAnimatedDots from '../Common/AnimatedDots.tsx';
import { HeaderDescription } from '../HomePage/PlansSection/PlansSection.styled';
import MemoMiniPlanCard from '../MiniPlanCard';
import MemoCloudHostingComparision from '../CloudHostingPage/CloudHostingComparision/CloudHostingComparision';
type Feature = {
  item: string,
  content: string
}

type MiniPlanCard = {
  id: number,
  padge: string,
  icon: string,
  title: string,
  features?: Feature[];
  description?: string,
  price: string,
  url: string,
  fav?: boolean
}

type SectionData = {
  greentitle?: string;
  title?: string;
  description?: string;
  cards: MiniPlanCard[];
}

type Props = {
  data?: SectionData;
}

const MiniPlansSection = ({data}: Props) => {
  return (
    <SectionWrapper>
      <SectionContent>
        <Container fluid>
          <Row>
            <Col lg={2} md={12}>
              <MemoAnimatedDots full position='relative' variant='left' />
            </Col>
            <Col xs={12} lg={12} >
              <SectionData>
                <GrrenTitle>{data?.greentitle} </GrrenTitle>
                <HeaderTitle>{data?.title} </HeaderTitle>
                <HeaderDescription>{data?.description}</HeaderDescription>
                <Row>
                  {data?.cards.map((card) => (
                    <Col lg={4} md={6} xs={12} key={card.id} >
                      <MemoMiniPlanCard
                        padge={card.padge}
                        title={card.title} 
                        features={card.features}
                        description={card.description} 
                        price={card.price}
                        fav={card.fav}
                        icon={card.icon}
                      />
                    </Col>
                  ))}
                  <Col xs={12} >
                    {/* <MemoCloudHostingComparision/> */}
                  </Col>
                </Row>
              </SectionData>
            </Col>
          </Row>
        </Container>
      </SectionContent>
    </SectionWrapper>
  )
}

const MemoMiniPlansSection = memo(MiniPlansSection);

export default MemoMiniPlansSection