import styled from "styled-components";

export const SectionWrapper = styled.section`
  min-height: 465px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: #fff;
  img {
    position: absolute;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    top: 0;
    right: 0 /*rtl: 600px */;
    z-index: 0;
  }
  .swiper-vertical > .swiper-pagination-bullets, .swiper-pagination-vertical.swiper-pagination-bullets {
    display: flex;
    gap: 25px;
    /* right: 60%; */
  }
  .swiper-pagination-bullet{
    background-color: #09264A;
    opacity: 1;
    min-width: 10px;
    min-height: 10px;
    width: 10px;
    height: 10px;
  }
  .swiper-pagination-bullet-active{
    background-color: #48988D
  }
  .swiper-slide{
    padding-bottom: 50px;
  }
`;

export const SectionContent = styled.div`
  max-width: 94vw;
  width: 100%;
  margin: auto;
`;

export const GrrenTitle = styled.h5`
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  color: #48988D;
  /* margin-bottom: 10px; */
  text-transform: capitalize;
  max-width: 621px;
`;

export const SectionTitle = styled.h4`
  font-size: 40px;
  font-weight: 700;
  line-height: 42px;
  color: #09264A;
  margin-bottom: 23px;
  max-width: 654px;
  text-transform: capitalize;

`;

export const SectionDescription = styled.p`
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  color: #39414C;
  max-width: 654px;
  margin-bottom: 38px;
`;