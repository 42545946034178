import { styled } from "styled-components";

export const Button = styled.button`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  padding: 12px 26px;
  border-bottom-left-radius: 10px;
  border: 1px solid #48988D;
  white-space: nowrap;
  background-color: #fff;
  cursor: pointer;
  &.filled{
    background-color: #48988D;
    color: #fff;
  }
  &.rounded{
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    
  }
`