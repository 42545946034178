import './App.css';
import { useTranslation } from 'react-i18next';
import React, { useLayoutEffect, useState } from 'react';
import AppRoutes from './Routes/AppRoutes';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import rtlPlugin from "stylis-plugin-rtl";
import { AppWrapper, GlobalStyles } from './GlobalStyle.styled';
// fonts
// import "./fonts/Poppins-Light.woff2";
// import "./fonts/Poppins-Regular.woff2";
// import "./fonts/Poppins-Medium.woff2";
// import "./fonts/Poppins-SemiBold.woff2";
// import "./fonts/Poppins-Bold.woff2";

export const theme = {
  primaryColor: '#000', 
};




function App() {
  const { i18n } = useTranslation();
  const [isRtl, setIsRTL] = useState(false)
  const dir = i18n.dir();
  useLayoutEffect(() => {
    document.body.setAttribute("dir", dir);
    dir === 'rtl' ? setIsRTL(true) : setIsRTL(false)
  }, [dir]);

  
  // const language = i18n.language;
  const checkRtl = () => {
    if (dir === 'rtl') {
    return [rtlPlugin];
  } else {
    return [];
  }
  }
  // const isRTL = dir;
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme} >
        <StyleSheetManager stylisPlugins={checkRtl()} >
          <AppWrapper className="App"  >
            <AppRoutes />
          </AppWrapper>
        </StyleSheetManager>
      </ThemeProvider>
    </>
  );
}

const MemoApp = React.memo(App)
export default MemoApp;
