import styled from "styled-components";

export const LoaderWrapper = styled.div`
  min-width: 100vw;
  min-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    transform: scale(3);
  }
  @media (max-width: 768px){
    img {
      transform: scale(2);
    }
  }
`;