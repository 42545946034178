import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export const NavWrapper = styled.nav`
  padding: 30px 40px;
  z-index: 11;
  @media (max-width: 992px) {
    padding: 30px 0;
  }
`;

export const Logo = styled.img`
  display: flex;
  max-width: 147.75px;
  min-width: 147.75px;
  height: auto;
`;



export const Ul = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 12px;
  margin: auto 0;
  list-style: none;
  padding: 0;
  @media (max-width: 1400px) {
    display: none;
  }
`;

export const SecoundUl = styled(Ul)`
  justify-content: center;
  margin: 16px 0 0 0;
  display: none;
  @media (max-width: 1400px) {
    display: flex;
  }
  @media (max-width: 992px) {
    display: none;
  }
`;

export const Li = styled.li`
  text-align: center
`;

export const NavLink = styled(Link)`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #011125;
  text-decoration: none;
  position: relative;
  padding-left: 12px;
  white-space: nowrap;
  &::before{
    position: absolute;
    content: '';
    left: 0;
    bottom: 6px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #011125;
  }
  &::hover {
    color: #011125;
  }
`;

export const HeaderActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: 8px;
  @media (max-width: 992px) {
    display: none;
  }
`;


// Define the container component
export const NavIcon = styled.div`
  position: relative;
  width: 40px; /* Adjust the width as needed */
  height: 35px; /* Adjust the height as needed */
  margin-left: auto;
  display: none;
  @media (max-width: 992px) {
    display: block;
  }
  & span {
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    background: #48988D;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  & span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  & span:nth-child(odd) {
    left: 0;
    border-radius: 9px 0 0 9px;
  }

  & span:nth-child(1), & span:nth-child(2) {
    top: 6px /* rtl: 50px */;
  }

  & span:nth-child(3), & span:nth-child(4) {
    top: 18px;
  }

  & span:nth-child(5), & span:nth-child(6) {
    top: 30px;
  }

  &.open span:nth-child(1), &.open span:nth-child(6) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(2), &.open span:nth-child(5) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(1) {
    left: 5px;
    top: 7px;
  }

  &.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }

  &.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  &.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  &.open span:nth-child(5) {
    left: 5px;
    top: 17px;
  }

  &.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 17px;
  }
  &:dir(rtl) {
    &.open span:nth-child(1), &.open span:nth-child(2){
      top: 15px;
    }
    &.open span:nth-child(5),  &.open span:nth-child(6){
      top: 5px;
    }
  }
`;

export const OverLay = styled.div<{
  readonly isOpen?: boolean;
}>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #00000020;
  z-index: 10;
  top: 102px;
  left: 0;
  opacity: 0;
  display: none;
  transition: 0.3s ease all;
  ${({isOpen}) => isOpen && css`
  opacity: 1;
  display: block;
  visibility: visible;
    
  `}
`;

export const DrawerWrapper = styled.div<{
  readonly isOpen?: boolean;
}>`
  position: fixed;
  height: calc(100vh - 168px);
  background-color: #fff;
  width: 60vw;
  top: 102px;
  right: 0%;
  z-index: 50;
  padding: 32px 16px;
  /* display: flex; */
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(100%);
  transition: 0.3s ease all;
  display: none;
  ${HeaderActionsWrapper} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    button{
      width: 100%;
    }
  }
  ${Ul} {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: auto;
    margin: 0;
  }
  ${HeaderActionsWrapper} {
    height: auto;
    margin-bottom: 50px;
    /* margin-top: auto; */
  }
  ${({isOpen}) => isOpen && css`
    right: 64%;
    /* transform: translateX(-64%) */
  `}
  @media (max-width: 992px){
    max-height: 100vh;
    display: flex;
  }
  @media (max-width: 570px){
    ${({isOpen}) => isOpen && css`
      right: 66%;
    /* transform: translateX(-64%) */
  `}
  }
`;